import React, { useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import { Icon } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { isEmpty, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { Typography } from 'components/index';
import Callout from '@setproduct-ui/core/Callout';
import Chips from '@setproduct-ui/core/Chips';
import OnChangeComponent from 'components/forms/OnChangeComponent';
import IsFormChanged from 'components/forms/IsFormChanged';
import { APPLICATION_STATUSES } from 'consts';
import {
  SENDER_SCHEMA,
  SIMPLE_SENDERS_SCHEMA,
  SMS_SENDER_SCHEMA,
} from 'consts/validationSchemas';
import { RCSIcon, SmsChannelIcon } from 'assets/icons';

import Footer from './Footer';
import SmsContent from './SmsContent';
import RcsContent from './RcsContent';
import styles from './ApplicationForm.module.scss';

const channelsOptions = [
  { value: 1, label: 'SMS', icon: <SmsChannelIcon width={20} height={20} /> },
  { value: 2, label: 'RCS', icon: <RCSIcon width={20} height={20} /> },
];

const ApplicationForm = ({
  partnersOptions,
  agreements,
  initialValues = {
    color: '#000000',
    phone: [
      {
        value: null,
        label: null,
      },
    ],
    website: [
      {
        value: null,
        label: null,
      },
    ],
    email: [
      {
        value: null,
        label: null,
      },
    ],
    senderStatus: 0,
  },
  senderValues,
  isPending,
  readOnly,
  leftButtonText,
  onLeftButtonClick,
  rightButtonText,
  onRightButtonClick,
  showControls = true,
  highlightChanged,
  onCancel,
  currentUserId,
  showHeader,
  onSenderClick,
  showTabs,
  formValuesRef,
  isFormPristine,
  mode,
  selectedChannel,
  smppConnectionsOptions,
}) => {
  const dropdownFieldStyles = ({ marginRight, marginLeft }) => ({
    container: {
      width: '100%',
      marginRight,
      marginLeft,
    },
  });
  const generateButtonStyles = {
    position: 'absolute',
    right: 8,
    top: 12,
    cursor: 'pointer',
  };

  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('display');
  const dispatch = useDispatch();
  const { activeTab: activeScreenTab } = useSelector(state => state.states.routes);

  const validationSchema = useMemo(() => {
    switch (selectedChannel) {
      case 1: return SIMPLE_SENDERS_SCHEMA.concat(SMS_SENDER_SCHEMA);
      case 2: return SENDER_SCHEMA;
      default: return SENDER_SCHEMA;
    }
  }, [selectedChannel]);

  const getAgreementsOptions = companyId => agreements.reduce((acc, item) => {
    if (item.companyId === companyId) {
      acc.push({
        label: `${item.agreementCode} (${item.currency})`,
        value: item.id,
      });
    }
    return acc;
  }, []);
  const onChangeCompany = ({ form }) => {
    if (form.dirty) {
      form.setFieldValue('agreementId', undefined);
    }
  };
  const checkFunction = (values) => {
    if (!isEmpty(values) && formValuesRef?.current) {
      formValuesRef.current = values;

      if (activeScreenTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }

    switch (mode) {
      case 'edit':
        return isEqual(values, initialValues);
      case 'add':
        return isEmpty(values);
      default: return true;
    }
  };
  const onSubmitModify = ({
    agreementId,
    vendorType,
    projectNumber,
    agentId,
    rbmConfig,
    login,
    password,
    tokenDuration,
    id,
    status,
    companyId: formCompanyId,
    vendorConfig,
    webHookGuid,
    senderStatus,
    senderId,
    senderIdId,
    ...values
  }) => {
    let bodyApplication = {
      id,
      companyId: formCompanyId,
      senderId,
      senderIdId,
      status: status || 2,
      channel: selectedChannel,
      senderStatus,
      displayName: values.displayName,
      agreementId,
      vendorType,
      webHookGuid,
    };
    switch (selectedChannel) {
      case 1: // SMS
        bodyApplication = {
          ...bodyApplication,
          vendorConfig: vendorType === 'smpp' ? {
            connectionId: values.connectionId,
            serviceType: values.serviceType,
          } : {
            login,
            password,
            serviceType: values.serviceType,
          },
          properties: {
            displayName: values.displayName,
            description: values.description,
            keyword: values.keyword,
            mcc: values.mcc,
          },
        };
        break;
      case 2: // RCS
        bodyApplication = {
          ...bodyApplication,
          logoUrl: values.logoUrl,
          vendorConfig: vendorType === 'google_rbm' ? (vendorConfig || null) : {
            login,
            password,
            tokenDuration,
          },
          properties: {
            displayName: values.displayName,
            logoUrl: values.logoUrl,
            bannerImageUrl: values.bannerImageUrl,
            description: values.description,
            phone: values.phone,
            website: values.website,
            email: values.email,
            ppPage: values.ppPage,
            tcPage: values.tcPage,
            color: values.color,
          },
        };
        break;
      default:
        bodyApplication = {
          ...bodyApplication,
          vendorConfig: {},
          properties: {},
        };
        break;
    }

    onRightButtonClick(bodyApplication);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitModify}
      enableReinitialize
      validationSchema={readOnly ? undefined : validationSchema}
      validateOnMount
    >
      {({
        values,
        setFieldTouched,
        errors,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form className={styles.form}>
          <div className={styles.shadow} />
          {
            showHeader && (
              <div className={styles.header}>
                <div className={styles.cell}>
                  <Chips
                    {...APPLICATION_STATUSES[initialValues.status.toString()]}
                    round={false}
                    type="dense"
                  />
                  <Typography className={styles.label}>
                    INSTANCES.STATUS
                  </Typography>
                </div>
                {
                  initialValues.senderId && (
                  <div className={styles.cell}>
                    <span
                      className={styles.senderLink}
                      role="presentation"
                      onClick={onSenderClick}
                    >
                      {initialValues.senderId}
                    </span>
                    <Typography className={styles.label}>
                      INSTANCES.SENDER_ID
                    </Typography>
                  </div>
                  )
                }
              </div>
            )
          }
          {
            selectedChannel === 1 ? (
              <SmsContent
                initialValues={initialValues}
                senderValues={senderValues}
                readOnly={readOnly}
                showControls={showControls}
                highlightChanged={highlightChanged}
                partnersOptions={partnersOptions}
                getAgreementsOptions={getAgreementsOptions}
                generateButtonStyles={generateButtonStyles}
                values={values}
                setFieldValue={setFieldValue}
                smppConnectionsOptions={smppConnectionsOptions}
                channelsOptions={channelsOptions}
                showTabs={showTabs}
              />
            ) : (
              <RcsContent
                initialValues={initialValues}
                senderValues={senderValues}
                readOnly={readOnly}
                highlightChanged={highlightChanged}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                generateButtonStyles={generateButtonStyles}
                dropdownFieldStyles={dropdownFieldStyles}
                getAgreementsOptions={getAgreementsOptions}
                channelsOptions={channelsOptions}
                showTabs={showTabs}
                partnersOptions={partnersOptions}
              />
            )
          }
          {
            readOnly && currentUserId === initialValues.managerUserId && initialValues.status === 1 && (
            <div className={styles.calloutWrapper}>
              <Callout
                view="smooth"
                title={t('SCREENS.APPLICATIONS.APPLICATION_ASSIGNED')}
                icon={<Icon icon="info-sign" color="var(--grey30)" size={20} />}
                className={styles.callout}
                dense
              >
                {t('SCREENS.APPLICATIONS.APPLICATION_ASSIGNED_DESC')}
              </Callout>
            </div>
            )
          }
          <Footer
            onLeftButtonClick={onLeftButtonClick}
            onCancel={onCancel}
            errors={errors}
            setFieldTouched={setFieldTouched}
            isPending={isPending}
            leftButtonText={leftButtonText}
            rightButtonText={rightButtonText}
            onRightButtonClick={handleSubmit}
            submitDisabled={false}
            showControls={showControls}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <OnChangeComponent field="companyId" onChange={onChangeCompany} />
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={checkFunction} />
        </Form>
      )}
    </Formik>
  );
};

export default ApplicationForm;
