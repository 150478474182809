import React from 'react';

import { OpenEnvelopIcon } from 'assets/icons';
import { getLanguageOptions } from 'helpers';

import { LANC_HOST } from './hosts';
import endpoints from './endpoints';
import * as inputPatterns from './inputPatterns';

const IS_LANC_HOST = window.location.origin.includes(LANC_HOST);

const DIRECTIONS = [
  { value: 0, label: 'STATES.PAYMENTS.INBOUND', color: 'primary_alt' },
  { value: 1, label: 'STATES.PAYMENTS.OUTBOUND', color: 'success_alt' },
];

const CHARGE_DIRECTIONS = [
  { value: '0', label: 'STATES.CHARGES.RECEIVABLE' },
];

const BLOCKED = [
  { value: '0', label: 'STATES.DISABLED', color: 'danger' },
  { value: '1', label: 'STATES.ACTIVE', color: 'success' },
];

const BLOCKED_SENDERS = [
  { value: '0', label: 'STATES.DISABLED', color: 'danger' },
  { value: '1', label: 'STATES.ACTIVE', color: 'success' },
];

const CONFIRMED = [
  { value: '0', label: 'STATES.PAYMENTS.NOT_CONFIRMED', color: 'danger' },
  { value: '1', label: 'STATES.PAYMENTS.CONFIRMED', color: 'success' },
];

const TAX_SCHEMES = [
  { value: 0, label: 'STATES.AGREEMENTS.TAX_INCLUDED' },
  { value: 1, label: 'STATES.AGREEMENTS.TAX_NOT_INCLUDED' },
];

const REPORTS_TYPES = [
  { value: 1, label: 'STATES.REPORTS.CHECKBOX', en: 'Checkbox' },
  { value: 2, label: 'STATES.REPORTS.NUMBER_FIELD', en: 'Number field' },
  { value: 3, label: 'STATES.REPORTS.SPINNER_FIELD', en: 'Spinner field' },
  { value: 4, label: 'STATES.REPORTS.COMBOBOX', en: 'Combobox' },
  { value: 5, label: 'STATES.REPORTS.MULTISELECT', en: 'Multiselect' },
  { value: 6, label: 'STATES.REPORTS.TEXT_FIELD', en: 'Text field' },
  { value: 7, label: 'STATES.REPORTS.DATE', en: 'Date' },
  { value: 8, label: 'STATES.REPORTS.DATE_AND_TIME', en: 'Date and time' },
  { value: 9, label: 'STATES.REPORTS.MONTH_AND_YEAR', en: 'Month and year' },
  { value: 10, label: 'STATES.REPORTS.YEAR', en: 'Year' },
];

const REPORTS_CATEGORIES = [
  { value: 1, label: 'STATES.REPORTS.TRAFFIC' },
  { value: 3, label: 'STATES.REPORTS.FINANCE' },
  { value: 4, label: 'STATES.REPORTS.AUDIT_LOG' },
];

const CHANNELS_DICTIONARY = {
  1: 'SMS',
  2: 'RCS',
  3: 'Viber',
  4: 'WhatsApp',
  5: 'VK/OK',
  7: 'Email',
};
const CHANNELS_OPTIONS = Object.entries(CHANNELS_DICTIONARY).map(([value, label]) => ({
  value: +value,
  label,
}));

const CHANNELS_STRING_VALUES = [
  { value: 'SMS', label: 'SMS' },
  { value: 'RCS', label: 'RCS' },
  { value: 'VIBER', label: 'Viber' },
  { value: 'WHATSAPP', label: 'WhatsApp' },
  { value: 'WE_CHAT', label: 'WeChat' },
  { value: 'EMAIL', label: 'Email' },
];

const MONTHS = [
  { value: 1, label: 'STATES.MONTHS.JANUARY' },
  { value: 2, label: 'STATES.MONTHS.FEBRUARY' },
  { value: 3, label: 'STATES.MONTHS.MARCH' },
  { value: 4, label: 'STATES.MONTHS.APRIL' },
  { value: 5, label: 'STATES.MONTHS.MAY' },
  { value: 6, label: 'STATES.MONTHS.JUNE' },
  { value: 7, label: 'STATES.MONTHS.JULY' },
  { value: 8, label: 'STATES.MONTHS.AUGUST' },
  { value: 9, label: 'STATES.MONTHS.SEPTEMBER' },
  { value: 10, label: 'STATES.MONTHS.OCTOBER' },
  { value: 11, label: 'STATES.MONTHS.NOVEMBER' },
  { value: 12, label: 'STATES.MONTHS.DECEMBER' },
];

const BILLING_PERIOD = [
  {
    value: 1,
    label: 'STATES.AGREEMENTS.DAILY',
  },
  {
    value: 7,
    label: 'STATES.AGREEMENTS.WEEKLY',
  },
  {
    value: 30,
    label: 'STATES.AGREEMENTS.MONTHLY',
  },
  {
    value: 60,
    label: 'STATES.AGREEMENTS.EVERY_2',
  },
  {
    value: 90,
    label: 'STATES.AGREEMENTS.EVERY_3',
  },
  {
    value: 12,
    label: 'STATES.AGREEMENTS.EVERY_4',
  },
];

const AGREEMENTS_BILLING_PERIOD = [
  {
    value: 1,
    label: 'STATES.AGREEMENTS.DAILY',
  },
  {
    value: 7,
    label: 'STATES.AGREEMENTS.WEEKLY',
  },
  {
    value: 30,
    label: 'STATES.AGREEMENTS.MONTHLY',
  },
  {
    value: 90,
    label: 'STATES.AGREEMENTS.QUARTERLY',
  },
];

const AGREEMENT_DIRECTIONS = [
  {
    value: 0,
    label: 'INSTANCES.AGREEMENTS.CLIENT',
  },
  {
    value: 1,
    label: 'INSTANCES.AGREEMENTS.VENDOR',
  },
];

const MODERATION_STATUSES = [
  {
    value: 1,
    label: 'INSTANCES.PRE_MODERATION.APPROVED',
  },
  {
    value: 2,
    label: 'INSTANCES.PRE_MODERATION.REJECTED',
  },
  {
    value: 3,
    label: 'INSTANCES.PRE_MODERATION.CANCELED',
  },
];

const BILLING_PERIOD_DAYS = [];

// eslint-disable-next-line no-plusplus
for (let i = 0; i < 31; i++) {
  BILLING_PERIOD_DAYS.push({
    value: i + 1,
    label: `${i + 1}`,
  });
}

const BILLING_DAY = [
  {
    value: 1,
    label: 'STATES.AGREEMENTS.MON',
  },
  {
    value: 2,
    label: 'STATES.AGREEMENTS.TUE',
  },
  {
    value: 3,
    label: 'STATES.AGREEMENTS.WED',
  },
  {
    value: 4,
    label: 'STATES.AGREEMENTS.THU',
  },
  {
    value: 5,
    label: 'STATES.AGREEMENTS.FRI',
  },
  {
    value: 6,
    label: 'STATES.AGREEMENTS.SAT',
  },
  {
    value: 7,
    label: 'STATES.AGREEMENTS.SUN',
  },
];

const FILTER_BUTTONS = [
  {
    value: 'all',
    label: 'CONTROLS.FILTER.SHOW_ALL',
  },
  {
    value: 'notempty',
    label: 'CONTROLS.FILTER.HIDE_EMPTY',
  },
  {
    value: 'empty',
    label: 'CONTROLS.FILTER.EMPTY_ONLY',
  },
];

const RATES_COLUMNS = [
  'INSTANCES.RATES.PRICE_NAME',
  'INSTANCES.RATES.EVENT',
  'INSTANCES.DIRECTION',
  'INSTANCES.TYPE',
  'INSTANCES.RATE',
];
const RATES_ROWS = [
  [
    {
      text: 'A2P',
    },
    {
      text: 'INSTANCES.RATES.MESSAGE',
    },
    {
      text: 'MT',
    },
    {
      text: 'INSTANCES.RATES.STANDALONE',
    },
    {
      name: '1',
    },
  ],
  [
    {
      text: 'P2A',
    },
    {
      text: 'INSTANCES.RATES.MESSAGE',
    },
    {
      text: 'MO',
    },
    {
      text: 'INSTANCES.RATES.STANDALONE',
    },
    {
      name: '2',
    },
  ],
  [
    {
      text: 'INSTANCES.RATES.STANDALONE_KEY',
      key: 'A2P',
    },
    {
      text: 'INSTANCES.RATES.MESSAGE',
    },
    {
      text: 'MT',
    },
    {
      text: 'INSTANCES.RATES.STANDALONE',
    },
    {
      name: '3',
    },
  ],
  [
    {
      text: 'INSTANCES.RATES.STANDALONE_KEY',
      key: 'P2A',
    },
    {
      text: 'INSTANCES.RATES.MESSAGE',
    },
    {
      text: 'MO',
    },
    {
      text: 'INSTANCES.RATES.STANDALONE',
    },
    {
      name: '4',
    },
  ],
  [
    {
      text: 'INSTANCES.RATES.SESSION',
    },
    {
      text: 'INSTANCES.RATES.SESSION',
    },
    {
      text: '',
    },
    {
      text: '',
    },
    {
      name: '5',
    },
  ],
];

const APPLICATION_STATUSES_OPTIONS = [
  { value: -2, label: 'STATES.APPLICATIONS.CANCELED', color: 'default' },
  { value: -1, label: 'STATES.APPLICATIONS.REJECTED', color: 'danger' },
  { value: 0, label: 'STATES.APPLICATIONS.SUBMITTED', color: 'warning' },
  { value: 1, label: 'STATES.APPLICATIONS.IN_PROGRESS', color: 'primary' },
  { value: 2, label: 'STATES.APPLICATIONS.DONE', color: 'success' },
  { value: 3, label: 'STATES.APPLICATIONS.DELETED', color: 'default' },
];
const APPLICATION_STATUSES = {
  '-2': {
    tag: 'STATES.APPLICATIONS.CANCELED', color: 'default',
  },
  '-1': {
    tag: 'STATES.APPLICATIONS.REJECTED', color: 'danger',
  },
  0: {
    tag: 'STATES.APPLICATIONS.SUBMITTED', color: 'warning',
  },
  1: {
    tag: 'STATES.APPLICATIONS.IN_PROGRESS', color: 'primary',
  },
  2: {
    tag: 'STATES.APPLICATIONS.DONE', color: 'success',
  },
  3: {
    tag: 'STATES.APPLICATIONS.DELETED', color: 'default',
  },
};
const APPLICATION_TYPES = [
  { value: 2, label: 'STATES.APPLICATIONS.CHANGE' },
  { value: 1, label: 'STATES.APPLICATIONS.CREATE' },
];
const SENDER_STATUSES = [
  { value: -1, label: 'STATES.SENDER_IDS.BLOCKED', style: { color: 'var(--red70' } },
  { value: 0, label: 'STATES.DRAFT', style: { color: 'var(--blue70' } },
  { value: 1, label: 'STATES.SENDER_IDS.TESTING', style: { color: 'var(--yellow70' } },
  { value: 2, label: 'STATES.SENDER_IDS.ACTIVE', style: { color: 'var(--green70' } },
];
const SENDER_ID_TYPES = [
  {
    value: 1,
    label: 'Alphanumeric',
  },
  {
    value: 2,
    label: 'Short code',
  },
  {
    value: 3,
    label: 'Long code',
  },
];
const INVOICE_DIRECTIONS = {
  0: {
    color: 'success_alt',
    iconColor: 'var(--teal60)',
    icon: 'arrow-down',
    label: 'STATES.INVOICES.RECEIVABLE',
  },
  1: {
    color: 'primary_alt',
    iconColor: 'var(--indigo60)',
    icon: 'arrow-up',
    label: 'STATES.INVOICES.PAYABLE',
  },
};
const INVOICE_STATUSES = {
  '-1': {
    color: 'default',
    iconColor: 'var(--grey60)',
    icon: 'eye-open',
    label: 'STATES.INVOICES.PREVIEW',
  },
  0: {
    color: 'primary',
    iconColor: 'var(--blue60)',
    icon: 'edit',
    label: 'STATES.DRAFT',
  },
  1: {
    color: 'success',
    iconColor: 'var(--green60)',
    icon: 'small-tick',
    label: 'STATES.CONFIRMED',
  },
};
const INVOICE_DISPATCH_STATUSES = {
  '-1': {
    color: 'danger',
    iconColor: 'var(--red60)',
    icon: 'info-sign',
    label: 'STATES.INVOICES.FAILED',
  },
  0: {
    color: 'default',
    iconColor: 'var(--grey60)',
    icon: 'cross',
    label: 'STATES.INVOICES.NOT_SENT',
  },
  1: {
    color: 'primary',
    iconColor: 'var(--blue60)',
    icon: 'envelope',
    label: 'STATES.INVOICES.SENT',
  },
  2: {
    color: 'success',
    iconColor: 'var(--green60)',
    icon: <OpenEnvelopIcon />,
    label: 'STATES.INVOICES.RECEIVED',
  },
};
const INVOICE_DISPUTE_STATUSES = {
  0: {
    color: 'default',
    iconColor: 'var(--grey60)',
    icon: 'small-tick',
    label: 'STATES.INVOICES.NOT_DISPUTED',
  },
  // todo: вернуть когда добавится поддержка фичи
  // 1: {
  //   color: 'danger',
  //   iconColor: 'var(--red60)',
  //   icon: 'info-sign',
  //   label: 'STATES.INVOICES.DISPUTED',
  // },
  2: {
    color: 'success',
    iconColor: 'var(--green60)',
    icon: 'small-tick',
    label: 'STATES.INVOICES.DISPUTE_SETTLED',
  },
};
const INVOICE_PAYMENT_STATUSES = {
  '-1': {
    color: 'danger',
    iconColor: 'var(--red60)',
    icon: 'time',
    label: 'STATES.INVOICES.OVERDUE',
  },
  0: {
    color: 'primary',
    iconColor: 'var(--blue60)',
    icon: 'time',
    label: 'STATES.INVOICES.EXPECTED',
  },
  1: {
    color: 'warning',
    iconColor: 'var(--orange60)',
    icon: 'small-tick',
    label: 'STATES.INVOICES.PARTIALLY_COVERED',
  },
  2: {
    color: 'success',
    iconColor: 'var(--green60)',
    icon: 'small-tick',
    label: 'STATES.INVOICES.FULLY_COVERED',
  },
};
const CHARGE_SOURCES = {
  0: {
    color: 'default',
    iconColor: 'var(--grey60)',
    icon: 'cog',
    label: 'STATES.CHARGES.AUTO',
  },
  1: {
    color: 'default',
    iconColor: 'var(--grey60)',
    icon: 'cog',
    label: 'STATES.CHARGES.MANUAL',
  },
};
const CHARGE_DUE_SOURCES = {
  0: 'STATES.CHARGES.SYSTEM_ESTIMATE',
  // todo: вернуть когда добавится поддержка фичи
  // 1: 'STATES.CHARGES.COUNTERPARTY_ESTIMATE',
};

const PACKS_BILLING_TYPES = [
  {
    value: false,
    label: 'INSTANCES.PACKS_ENABLED.FREE',
  },
  {
    value: true,
    label: 'INSTANCES.PACKS_ENABLED.CHARGE',
  },
];

const LIMITS = [10, 50, 100, 200, 500];

const SMS_VENDOR_TYPES = [
  {
    value: 'alaris_http_sms',
    label: 'Alaris http SMS',
  },
  {
    value: 'smpp',
    label: 'SMPP',
  },
];

const WHATSAPP_VENDOR_TYPES = [
  {
    value: 'dialog360',
    label: 'Dialog360',
  },
  {
    value: 'nexmo',
    label: 'Nexmo',
  },
  {
    value: 'mkit_http_telin',
    label: 'Telin',
  },
  {
    value: 'mkit_http_gupshup',
    label: 'Gupshup',
  },
  {
    value: 'mkit_http_dialog360cloud',
    label: 'Dialog360 Cloud',
  },
];

const VIBER_VENDOR_TYPES_OPTIONS = [
  {
    value: 'mkit_http_viber',
    label: 'mKit http Viber',
  },
  {
    value: 'alaris_http_viber',
    label: 'Alaris http Viber',
  },
];

const VK_OK_VENDOR_TYPES_OPTIONS = [
  {
    value: 'mkit_http_vk_ok',
    label: 'mKit http VK/OK',
  },
];

const RCS_VENDOR_TYPES = [
  {
    value: 'google_rbm',
    label: 'Google RBM',
  },
  {
    value: 'alaris_gsma',
    label: 'GSMA TRANSIT',
  },
];

const EMAIL_VENDOR_TYPES = [
  {
    value: 'mkit_http_elasticemail',
    label: 'Elasticemail',
  },
];

const EMAIL_NOTIFICATION_PROTOCOLS = [
  {
    value: 'PROTOCOL_SSLv23',
    label: 'PROTOCOL_SSLv23',
  },
  {
    value: 'PROTOCOL_TLSv1',
    label: 'PROTOCOL_TLSv1',
  },
  {
    value: 'PROTOCOL_TLSv1_1',
    label: 'PROTOCOL_TLSv1_1',
  },
  {
    value: 'PROTOCOL_TLSv1_2',
    label: 'PROTOCOL_TLSv1_2',
  },
  {
    value: 'PROTOCOL_TLS',
    label: 'PROTOCOL_TLS',
  },
];

const CHANNELS_BY_ID = {
  1: 'SMS',
  2: 'RCS',
  3: 'Viber',
  4: 'WhatsApp',
};

const SERVICE_TYPES_DICTIONARY = {
  1: 'Pay-as-you-go',
  2: 'Unit pack',
  3: 'Monetary pack',
};
const SERVICE_TYPES_OPTIONS = Object.entries(SERVICE_TYPES_DICTIONARY).map(([value, label]) => ({
  value: +value,
  label,
}));

const HTTP_VENDOR_TYPES_DICTIONARY = {
  mkit_http_viber: 'mKit http Viber',
  mkit_http_vk_ok: 'mKit http VK/OK',
  mkit_http_telin: 'mKit http Telin',
  mkit_http_gupshup: 'mkit http Gupshup',
  mkit_http_elasticemail: 'mKit http Elasticemail',
  mkit_http_dialog360cloud: 'mKit http Dialog360 Cloud',
};
const HTTP_VENDOR_TYPES_OPTIONS = Object.entries(HTTP_VENDOR_TYPES_DICTIONARY)
  .map(([value, label]) => ({
    value,
    label,
  }));

const UNIT_MONETARY_PRICE_TYPES_OPTIONS = [
  {
    value: '1/price',
    label: 'STATES.SERVICES.INCLUDED',
    style: {
      color: 'var(--green50)',
    },
    onlyOptionStyle: {
      borderTop: '1px solid var(--grey5)',
    },
  },
  {
    value: '0/price',
    label: 'STATES.SERVICES.NOT_INCLUDED',
    style: {
      color: 'var(--orange50)',
    },
    onlyOptionStyle: {
      borderTop: '1px solid var(--grey5)',
    },
  },
];
const UNIT_MONETARY_PRICE_TYPES_DICTIONARY = UNIT_MONETARY_PRICE_TYPES_OPTIONS.reduce((acc, item) => {
  acc[item.value] = {
    ...item,
  };

  return acc;
}, {});

const PAYG_PRICE_TYPES_OPTIONS = [
  {
    value: '1/price',
    label: 'STATES.SERVICES.FLAT',
    style: {
      color: 'var(--green50)',
    },
    onlyOptionStyle: {
      borderTop: '1px solid var(--grey5)',
    },
  },
  {
    value: '2/price',
    label: 'STATES.SERVICES.SCALE',
    style: {
      color: 'var(--green70)',
    },
  },
  {
    value: '3/price',
    label: 'STATES.SERVICES.FREE',
    style: {
      color: 'var(--blue50)',
    },
  },
  {
    value: '0/price',
    label: 'STATES.SERVICES.NOT_INCLUDED',
    style: {
      color: 'var(--orange50)',
    },
    onlyOptionStyle: {
      borderTop: '1px solid var(--grey5)',
    },
  },
  {
    value: '-1/price',
    label: 'STATES.SERVICES.BLOCKED',
    style: {
      color: 'var(--red50)',
    },
  },
];
const PAYG_PRICE_TYPES_DICTIONARY = PAYG_PRICE_TYPES_OPTIONS.reduce((acc, item) => {
  acc[item.value] = {
    ...item,
  };

  return acc;
}, {});

const PORTALS_STATUSES_OPTIONS = [
  {
    value: '-1',
    label: 'STATES.PORTALS.ERROR',
    color: 'danger',
  },
  {
    value: 0,
    label: 'STATES.DRAFT',
    color: 'default',
  },
  {
    value: 1,
    label: 'STATES.ACTIVE',
    color: 'success',
  },
  {
    value: 2,
    label: 'STATES.ACTIVE',
    color: 'warning',
  },
];
const PORTALS_STATUSES_DICTIONARY = PORTALS_STATUSES_OPTIONS.reduce((acc, item) => {
  acc[item.value] = {
    ...item,
  };

  return acc;
}, {});

const LANGUAGES_OPTIONS = getLanguageOptions();

const MANUAL_LINK = '/docs/manual/bo';

const MIME_TYPES = {
  abs: 'audio/x-mpeg',
  ai: 'application/postscript',
  aif: 'audio/x-aiff',
  aifc: 'audio/x-aiff',
  aiff: 'audio/x-aiff',
  aim: 'application/x-aim',
  art: 'image/x-jg',
  asf: 'video/x-ms-asf',
  asx: 'video/x-ms-asf',
  au: 'audio/basic',
  avi: 'video/x-msvideo',
  avx: 'video/x-rad-screenplay',
  bcpio: 'application/x-bcpio',
  bin: 'application/octet-stream',
  bmp: 'image/bmp',
  body: 'text/html',
  cdf: 'application/x-cdf',
  cer: 'application/pkix-cert',
  class: 'application/java',
  cpio: 'application/x-cpio',
  csh: 'application/x-csh',
  css: 'text/css',
  dib: 'image/bmp',
  doc: 'application/msword',
  dtd: 'application/xml-dtd',
  dv: 'video/x-dv',
  dvi: 'application/x-dvi',
  eot: 'application/vnd.ms-fontobject',
  eps: 'application/postscript',
  etx: 'text/x-setext',
  exe: 'application/octet-stream',
  gif: 'image/gif',
  gtar: 'application/x-gtar',
  gz: 'application/x-gzip',
  hdf: 'application/x-hdf',
  hqx: 'application/mac-binhex40',
  htc: 'text/x-component',
  htm: 'text/html',
  html: 'text/html',
  ief: 'image/ief',
  jad: 'text/vnd.sun.j2me.app-descriptor',
  jar: 'application/java-archive',
  java: 'text/x-java-source',
  jnlp: 'application/x-java-jnlp-file',
  jpe: 'image/jpeg',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  js: 'application/javascript',
  jsf: 'text/plain',
  json: 'application/json',
  jspf: 'text/plain',
  kar: 'audio/midi',
  latex: 'application/x-latex',
  m3u: 'audio/x-mpegurl',
  mac: 'image/x-macpaint',
  man: 'text/troff',
  mathml: 'application/mathml+xml',
  me: 'text/troff',
  mid: 'audio/midi',
  midi: 'audio/midi',
  mif: 'application/x-mif',
  mov: 'video/quicktime',
  movie: 'video/x-sgi-movie',
  mp1: 'audio/mpeg',
  mp2: 'audio/mpeg',
  mp3: 'audio/mpeg',
  mp4: 'video/mp4',
  mpa: 'audio/mpeg',
  mpe: 'video/mpeg',
  mpeg: 'video/mpeg',
  mpega: 'audio/x-mpeg',
  mpg: 'video/mpeg',
  mpv2: 'video/mpeg2',
  ms: 'application/x-wais-source',
  nc: 'application/x-netcdf',
  oda: 'application/oda',
  odb: 'application/vnd.oasis.opendocument.database',
  odc: 'application/vnd.oasis.opendocument.chart',
  odf: 'application/vnd.oasis.opendocument.formula',
  odg: 'application/vnd.oasis.opendocument.graphics',
  odi: 'application/vnd.oasis.opendocument.image',
  odm: 'application/vnd.oasis.opendocument.text-master',
  odp: 'application/vnd.oasis.opendocument.presentation',
  ods: 'application/vnd.oasis.opendocument.spreadsheet',
  odt: 'application/vnd.oasis.opendocument.text',
  otg: 'application/vnd.oasis.opendocument.graphics-template',
  oth: 'application/vnd.oasis.opendocument.text-web',
  otp: 'application/vnd.oasis.opendocument.presentation-template',
  ots: 'application/vnd.oasis.opendocument.spreadsheet-template',
  ott: 'application/vnd.oasis.opendocument.text-template',
  ogx: 'application/ogg',
  ogv: 'video/ogg',
  oga: 'audio/ogg',
  ogg: 'audio/ogg',
  otf: 'application/x-font-opentype',
  spx: 'audio/ogg',
  flac: 'audio/flac',
  anx: 'application/annodex',
  axa: 'audio/annodex',
  axv: 'video/annodex',
  xspf: 'application/xspf+xml',
  pbm: 'image/x-portable-bitmap',
  pct: 'image/pict',
  pdf: 'application/pdf',
  pgm: 'image/x-portable-graymap',
  pic: 'image/pict',
  pict: 'image/pict',
  pls: 'audio/x-scpls',
  png: 'image/png',
  pnm: 'image/x-portable-anymap',
  pnt: 'image/x-macpaint',
  ppm: 'image/x-portable-pixmap',
  ppt: 'application/vnd.ms-powerpoint',
  pps: 'application/vnd.ms-powerpoint',
  ps: 'application/postscript',
  psd: 'image/vnd.adobe.photoshop',
  qt: 'video/quicktime',
  qti: 'image/x-quicktime',
  qtif: 'image/x-quicktime',
  ras: 'image/x-cmu-raster',
  rdf: 'application/rdf+xml',
  rgb: 'image/x-rgb',
  rm: 'application/vnd.rn-realmedia',
  roff: 'text/troff',
  rtf: 'application/rtf',
  rtx: 'text/richtext',
  sfnt: 'application/font-sfnt',
  sh: 'application/x-sh',
  shar: 'application/x-shar',
  sit: 'application/x-stuffit',
  snd: 'audio/basic',
  src: 'application/x-wais-source',
  sv4cpio: 'application/x-sv4cpio',
  sv4crc: 'application/x-sv4crc',
  svg: 'image/svg+xml',
  svgz: 'image/svg+xml',
  webp: 'image/webp',
  swf: 'application/x-shockwave-flash',
  t: 'text/troff',
  tar: 'application/x-tar',
  tcl: 'application/x-tcl',
  tex: 'application/x-tex',
  texi: 'application/x-texinfo',
  texinfo: 'application/x-texinfo',
  tif: 'image/tiff',
  tiff: 'image/tiff',
  tr: 'text/troff',
  tsv: 'text/tab-separated-values',
  ttf: 'application/x-font-ttf',
  txt: 'text/plain',
  ulw: 'audio/basic',
  ustar: 'application/x-ustar',
  vxml: 'application/voicexml+xml',
  xbm: 'image/x-xbitmap',
  xht: 'application/xhtml+xml',
  xhtml: 'application/xhtml+xml',
  xls: 'application/vnd.ms-excel',
  xml: 'application/xml',
  xpm: 'image/x-xpixmap',
  xsl: 'application/xml',
  xslt: 'application/xslt+xml',
  xul: 'application/vnd.mozilla.xul+xml',
  xwd: 'image/x-xwindowdump',
  vsd: 'application/vnd.visio',
  wav: 'audio/x-wav',
  wbmp: 'image/vnd.wap.wbmp',
  wml: 'text/vnd.wap.wml',
  wmlc: 'application/vnd.wap.wmlc',
  wmls: 'text/vnd.wap.wmlsc',
  wmlscriptc: 'application/vnd.wap.wmlscriptc',
  wmv: 'video/x-ms-wmv',
  woff: 'application/font-woff',
  woff2: 'application/font-woff2',
  wrl: 'model/vrml',
  wspolicy: 'application/wspolicy+xml',
  z: 'application/x-compress',
  zip: 'application/zip',
};

const WHATSAPP_TEMPLATES_STATUSES = [
  {
    value: 0,
    label: 'CONTROLS.WHATS_APP_TEMPLATES.DRAFT',
  },
  {
    value: 1,
    label: 'CONTROLS.WHATS_APP_TEMPLATES.SUBMITTED',
  },
  {
    value: 2,
    label: 'CONTROLS.WHATS_APP_TEMPLATES.APPROVED',
  },
  {
    value: -1,
    label: 'CONTROLS.WHATS_APP_TEMPLATES.REJECTED',
  },
  {
    value: -2,
    label: 'CONTROLS.WHATS_APP_TEMPLATES.DELETED',
  },
  {
    value: 3,
    label: 'CONTROLS.WHATS_APP_TEMPLATES.IN_APPEAL',
  },
  {
    value: 4,
    label: 'CONTROLS.WHATS_APP_TEMPLATES.PENDING',
  },
  {
    value: 5,
    label: 'CONTROLS.WHATS_APP_TEMPLATES.PENDING_DELETION',
  },
  {
    value: 6,
    label: 'CONTROLS.WHATS_APP_TEMPLATES.DISABLED',
  },
  {
    value: 7,
    label: 'CONTROLS.WHATS_APP_TEMPLATES.PAUSED',
  },
  {
    value: 8,
    label: 'CONTROLS.WHATS_APP_TEMPLATES.LIMIT_EXCEEDED',
  },
];

const MODERATION_CONTENT_TYPES = {
  CAMPAIGN: {
    key: 0,
    label: 'INSTANCES.PRE_MODERATION.CAMPAIGN',
    icon: 'envelope',
    color: 'var(--blue70)',
  },
  CHATBOT: {
    key: 1,
    label: 'INSTANCES.PRE_MODERATION.CHATBOT',
    icon: 'chat',
    color: 'var(--green70)',
  },
  BROADCAST: {
    key: 2,
    label: 'INSTANCES.PRE_MODERATION.BROADCAST',
    icon: 'envelope',
    color: 'var(--blue70)',
  },
  TEMPLATE: {
    key: 3,
    label: 'INSTANCES.PRE_MODERATION.TEMPLATE',
    icon: 'envelope',
    color: 'var(--blue70)',
  },
};

const MODERATION_CONTENT_TYPES_DICTIONARY = Object.values(MODERATION_CONTENT_TYPES)
  .reduce((acc, item) => {
    acc[item.key] = {
      ...item,
    };
    return acc;
  }, {});

const MODERATION_CONTENT_TYPES_OPTIONS = Object.values(MODERATION_CONTENT_TYPES)
  .reduce((acc, item) => {
    acc.push({
      value: item.key,
      label: item.label,
    });

    return acc;
  }, []);

export {
  endpoints,
  inputPatterns,
  DIRECTIONS,
  BILLING_PERIOD,
  BILLING_DAY,
  BLOCKED,
  CONFIRMED,
  FILTER_BUTTONS,
  CHANNELS_DICTIONARY,
  CHANNELS_OPTIONS,
  RATES_COLUMNS,
  RATES_ROWS,
  LIMITS,
  BLOCKED_SENDERS,
  BILLING_PERIOD_DAYS,
  TAX_SCHEMES,
  REPORTS_TYPES,
  REPORTS_CATEGORIES,
  CHARGE_DIRECTIONS,
  MONTHS,
  APPLICATION_STATUSES_OPTIONS,
  APPLICATION_TYPES,
  SENDER_STATUSES,
  APPLICATION_STATUSES,
  SENDER_ID_TYPES,
  AGREEMENT_DIRECTIONS,
  AGREEMENTS_BILLING_PERIOD,
  INVOICE_DIRECTIONS,
  INVOICE_STATUSES,
  INVOICE_DISPATCH_STATUSES,
  INVOICE_DISPUTE_STATUSES,
  INVOICE_PAYMENT_STATUSES,
  CHARGE_SOURCES,
  CHARGE_DUE_SOURCES,
  PACKS_BILLING_TYPES,
  SMS_VENDOR_TYPES,
  WHATSAPP_VENDOR_TYPES,
  RCS_VENDOR_TYPES,
  EMAIL_NOTIFICATION_PROTOCOLS,
  SERVICE_TYPES_DICTIONARY,
  SERVICE_TYPES_OPTIONS,
  CHANNELS_BY_ID,
  HTTP_VENDOR_TYPES_DICTIONARY,
  HTTP_VENDOR_TYPES_OPTIONS,
  VIBER_VENDOR_TYPES_OPTIONS,
  VK_OK_VENDOR_TYPES_OPTIONS,
  EMAIL_VENDOR_TYPES,
  UNIT_MONETARY_PRICE_TYPES_OPTIONS,
  UNIT_MONETARY_PRICE_TYPES_DICTIONARY,
  PAYG_PRICE_TYPES_OPTIONS,
  PAYG_PRICE_TYPES_DICTIONARY,
  MODERATION_STATUSES,
  MIME_TYPES,
  CHANNELS_STRING_VALUES,
  PORTALS_STATUSES_OPTIONS,
  PORTALS_STATUSES_DICTIONARY,
  LANGUAGES_OPTIONS,
  MANUAL_LINK,
  IS_LANC_HOST,
  WHATSAPP_TEMPLATES_STATUSES,
  MODERATION_CONTENT_TYPES_DICTIONARY,
  MODERATION_CONTENT_TYPES_OPTIONS,
};
