const LANGUAGES = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Русский',
    value: 'ru',
  },
  {
    label: 'Español',
    value: 'es',
  },
];

export {
  LANGUAGES,
};
