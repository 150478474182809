import React, {
  useMemo, useRef, useState, useEffect,
} from 'react';
import {
  Formik, Form, Field, FieldArray,
} from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Icon } from '@blueprintjs/core';
import cx from 'classnames';
import moment from 'moment';

import Callout from '@setproduct-ui/core/Callout';
import Button from '@setproduct-ui/core/Button';
import {
  DropdownField, TextField, RadioButtonField, CheckboxField,
} from 'components/fields';
import { FormButtonsGroup } from 'components/blocks';
import { useContentShadow, useToken } from 'hooks';
import { useDictionariesApi } from 'hooks/api';
import IsFormChanged from 'components/forms/IsFormChanged';
import OnChangeComponent from 'components/forms/OnChangeComponent';
import { PORTAL_SCHEMA } from 'consts/validationSchemas';
import endpoints from 'consts/endpoints';

import ImageField from './ImageField';
import LocalizationFieldArray from './LocalizationFieldArray';
import styles from './PortalForm.module.scss';

const CERTIFICATE_OPTIONS = [
  {
    value: 1,
    label: 'SCREENS.PORTALS.MANAGED_AUTOMATICALLY',
    helperText: 'SCREENS.PORTALS.MANAGED_AUTOMATICALLY_DESC',
  },
  {
    value: 2,
    label: 'SCREENS.PORTALS.MANAGED_MANUALLY',
    helperText: 'SCREENS.PORTALS.MANAGED_MANUALLY_DESC',
  },
];

const PortalForm = ({
  initialValues = {
    loginTitle: [
      { language: 'en', text: '' },
    ],
    poweredBy: true,
    certificateManagementOption: 2,
  },
  onSubmit,
  mode,
  onDelete,
  onCancel,
  changeMode,
  formValuesRef,
  isPending,
  isFormPristine,
  serverIP,
}) => {
  const checkBoxStyles = {
    container: {
      marginLeft: 0,
      marginTop: 14,
    },
  };
  const fieldStyle = ({ width, marginTop } = {}) => ({
    container: {
      width,
    },
    label: {
      marginTop,
    },
  });

  const { t } = useTranslation();
  const { token } = useToken();
  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.states.routes);
  const contentRef = useRef(null);
  const {
    showTopShadow,
    showBottomShadow,
  } = useContentShadow(contentRef);
  const { companiesOptionsWithOwn } = useDictionariesApi();
  const [domainStatus, setDomainStatus] = useState({
    iconConfig: {
      icon: 'help',
      color: 'var(--grey60)',
    },
    text: t('SCREENS.PORTALS.DOMAIN_HINT'),
  });
  const [certStatus, setCertStatus] = useState({
    iconConfig: {
      icon: 'help',
      color: 'var(--grey60)',
    },
    text: t('SCREENS.PORTALS.CERTIFICATE_HINT'),
  });

  const isView = useMemo(() => mode === 'view', [mode]);

  const changeToEdit = () => changeMode('edit');
  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }

    switch (mode) {
      case 'edit':
        return isEqual(values, initialValues);
      case 'add':
        return isEmpty(values);
      default: return true;
    }
  };
  const checkDomain = (fqdn) => {
    fetch(endpoints.getCheckDomainUrl(fqdn), {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setDomainStatus({
            isValid: true,
            iconConfig: {
              icon: 'tick-circle',
              color: 'var(--green60)',
            },
            text: 'Registered properly',
          });
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      })
      .catch(() => {
        setDomainStatus({
          isValid: false,
          iconConfig: {
            icon: 'warning-sign',
            color: 'var(--red60)',
          },
          text: 'Not properly registered',
        });
      });
  };
  const onFqdnChange = ({ form }) => {
    if (form.dirty) {
      setDomainStatus({
        iconConfig: {
          icon: 'help',
          color: 'var(--grey60)',
        },
        text: t('SCREENS.PORTALS.DOMAIN_HINT'),
      });
      setCertStatus({
        iconConfig: {
          icon: 'help',
          color: 'var(--grey60)',
        },
        text: t('SCREENS.PORTALS.CERTIFICATE_HINT'),
      });
    }
  };
  const onSubmitModify = (values) => {
    onSubmit({
      ...values,
      status: domainStatus.isValid ? 1 : 0,
    });
  };

  useEffect(() => {
    if (initialValues.fqdn) {
      checkDomain(initialValues.fqdn);

      fetch(endpoints.getCertCheckUrl(initialValues.fqdn), {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((response) => {
          switch (response.status) {
            case 'ok':
              setCertStatus({
                iconConfig: {
                  icon: 'tick-circle',
                  color: 'var(--green60)',
                },
                text: `Certificate is valid (expires ${moment.utc(response.expireDate).format('DD.MM.YYYY HH:mm:ss')} UTC)`,
              });
              break;
            case 'no_connect':
              setCertStatus({
                iconConfig: {
                  icon: 'warning-sign',
                  color: 'var(--red60)',
                },
                text: 'Certificate status unknown: unable to connect',
              });
              break;
            case 'expired':
              setCertStatus({
                iconConfig: {
                  icon: 'warning-sign',
                  color: 'var(--orange60)',
                },
                text: `Certificate expired ${moment.utc(response.expireDate).format('DD.MM.YYYY HH:mm:ss')} UTC`,
              });
              break;
            case 'self_signed':
              setCertStatus({
                iconConfig: {
                  icon: 'warning-sign',
                  color: 'var(--orange60)',
                },
                text: `Certificate is self-signed (expires ${moment.utc(response.expireDate).format('DD.MM.YYYY HH:mm:ss')} UTC)`,
              });
              break;
            default: break;
          }
        })
        .catch(() => {
          setCertStatus({
            iconConfig: {
              icon: 'warning-sign',
              color: 'var(--red60)',
            },
            text: 'Certificate status unknown: unable to connect',
          });
        });
    }
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmitModify}
      validationSchema={PORTAL_SCHEMA}
      validateOnMount
    >
      {({
        handleSubmit,
        errors,
        setFieldTouched,
        values,
      }) => (
        <Form className={styles.form}>
          {showTopShadow && <div className={styles.shadow} />}
          <div className={styles.content} ref={contentRef}>
            <div className={styles.row}>
              <Field
                name="companyId"
                label="INSTANCES.COMPANY"
                component={DropdownField}
                options={companiesOptionsWithOwn}
                placeholder="PLACEHOLDERS.SELECT"
                disabled={isView}
                styles={{ container: { width: '100%' } }}
                isRequired={!isView}
              />
              <Field
                name="name"
                label="INSTANCES.NAME"
                component={TextField}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                disabled={isView}
                isRequired={!isView}
                full
              />
            </div>
            <span
              className={styles.sectionTitle}
              style={{ marginTop: 24 }}
              data-testid={`${window.location.pathname}/subtitle/domain-name`}
            >
              {t('SCREENS.PORTALS.DOMAIN_NAME')}
            </span>
            <Callout
              view="smooth"
              icon={<Icon icon="warning-sign" color="var(--grey70)" size={20} />}
              className={styles.callout}
              dense
            >
              <div dangerouslySetInnerHTML={{ __html: t('SCREENS.PORTALS.DOMAIN_CALLOUT', { ip: serverIP }) }} />
            </Callout>
            <div className={cx(styles.row, styles.row_smallGap)}>
              <Field
                name="fqdn"
                label="INSTANCES.PORTALS.DOMAIN_NAME"
                component={TextField}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                disabled={isView}
                full
              />
              <Button
                onClick={() => checkDomain(values.fqdn)}
                color="success"
                text="CONTROLS.PORTALS.CHECK"
                disabled={!values.fqdn}
              />
            </div>
            <div
              className={styles.hint}
              data-testid={`${window.location.pathname}/hint/domain`}
            >
              <Icon
                size={16}
                {...domainStatus.iconConfig}
              />
              {domainStatus.text}
            </div>
            <span
              className={styles.sectionTitle}
              style={{ marginTop: 14 }}
              data-testid={`${window.location.pathname}/subtitle/certificate-management`}
            >
              {t('SCREENS.PORTALS.CERTIFICATE_MANAGEMENT')}
            </span>
            <Callout
              view="smooth"
              icon={<Icon icon="warning-sign" color="var(--grey70)" size={20} />}
              className={styles.callout}
              dense
            >
              {t('SCREENS.PORTALS.CERTIFICATE_CALLOUT')}
            </Callout>
            <Field
              name="certificateManagementOption"
              component={RadioButtonField}
              options={CERTIFICATE_OPTIONS}
              styles={checkBoxStyles}
              color="default"
              type="def"
              disabled={isView || (initialValues.status === 1 && values.fqdn === initialValues.fqdn)}
            />
            <div
              className={styles.hint}
              data-testid={`${window.location.pathname}/hint/cert`}
            >
              <Icon
                size={16}
                {...certStatus.iconConfig}
              />
              {certStatus.text}
            </div>
            <span
              className={styles.sectionTitle}
              style={{ marginTop: 24 }}
              data-testid={`${window.location.pathname}/subtitle/branding`}
            >
              {t('SCREENS.PORTALS.BRANDING')}
            </span>
            <Callout
              view="smooth"
              icon={<Icon icon="warning-sign" color="var(--grey70)" size={20} />}
              className={styles.callout}
              dense
            >
              {t('SCREENS.PORTALS.BRANDING_CALLOUT')}
            </Callout>
            <Field
              name="pageTitle"
              label="INSTANCES.PORTALS.PAGE_TITLE"
              component={TextField}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              styles={{ label: { marginTop: 14 } }}
              disabled={isView}
              full
            />
            <span className={styles.brandingTitle} style={{ marginTop: 14 }}>
              {t('SCREENS.PORTALS.MAIN_MENU_LOGO')}
            </span>
            <div className={styles.logoRow}>
              <Field
                name="logoSquareLight"
                component={ImageField}
                label="SCREENS.PORTALS.SQUARE_LOGO_LIGHT"
                ratio={t('SCREENS.PORTALS.RATIO_RECOMMENDED', { ratio: '1:1' })}
                inputStyle={{ width: 131, height: 131 }}
                disabled={isView}
              />
              <Field
                name="logoExpandedLight"
                component={ImageField}
                label="SCREENS.PORTALS.EXPANDED_LOGO_LIGHT"
                ratio={t('SCREENS.PORTALS.RATIO_RECOMMENDED', { ratio: '4:1' })}
                inputStyle={{ width: 466, height: 131 }}
                disabled={isView}
              />
            </div>
            <div className={styles.logoRow}>
              <Field
                name="logoSquareDark"
                component={ImageField}
                label="SCREENS.PORTALS.SQUARE_LOGO_DARK"
                ratio={t('SCREENS.PORTALS.RATIO_RECOMMENDED', { ratio: '1:1' })}
                inputStyle={{ width: 131, height: 131, backgroundColor: '#3A3A44' }}
                disabled={isView}
              />
              <Field
                name="logoExpandedDark"
                component={ImageField}
                label="SCREENS.PORTALS.EXPANDED_LOGO_DARK"
                ratio={t('SCREENS.PORTALS.RATIO_RECOMMENDED', { ratio: '4:1' })}
                inputStyle={{ width: 466, height: 131, backgroundColor: '#3A3A44' }}
                disabled={isView}
              />
            </div>
            <span className={styles.fileHint}>
              {t('SCREENS.PORTALS.LOGO_HINT')}
            </span>
            <span className={styles.brandingTitle} style={{ marginTop: 24 }}>
              {t('SCREENS.PORTALS.FAVICON')}
            </span>
            <div className={styles.logoRow}>
              <Field
                name="favicon"
                component={ImageField}
                inputStyle={{ width: 102, height: 102 }}
                disabled={isView}
              />
              <span className={styles.fileHint} style={{ marginTop: 0, marginLeft: 24 }}>
                {t('SCREENS.PORTALS.FAVICON_HINT')}
              </span>
            </div>
            <span className={styles.brandingTitle} style={{ marginTop: 24, marginBottom: 14 }}>
              {t('SCREENS.PORTALS.LOGIN_PAGE')}
            </span>
            <Field
              name="loginLogo"
              component={ImageField}
              label="SCREENS.PORTALS.LOGIN_PAGE_LOGO"
              ratio={t('SCREENS.PORTALS.LOGIN_LOGO_RATIO')}
              inputStyle={{ width: '100%', height: 200, maxWidth: 722 }}
              disabled={isView}
            />
            <FieldArray
              name="loginTitle"
              render={props => (
                <LocalizationFieldArray
                  {...props}
                  disabled={isView}
                />
              )}
            />
            <Field
              name="loginBgs"
              component={ImageField}
              label="SCREENS.PORTALS.LOGIN_PAGE_BG"
              ratio={t('SCREENS.PORTALS.RATIO_RECOMMENDED', { ratio: '16:9' })}
              inputStyle={{
                width: '100%', height: 437, maxWidth: 722, objectFit: 'cover',
              }}
              disabled={isView}
            />
            <Field
              name="poweredBy"
              component={CheckboxField}
              text="SCREENS.PORTALS.SHOW_POWERED_BY"
              disabled={isView}
              styles={fieldStyle({ marginTop: 16 })}
            />
          </div>
          <FormButtonsGroup
            onDelete={onDelete}
            onCancel={onCancel}
            setFieldTouched={setFieldTouched}
            errors={errors}
            onApply={isView ? changeToEdit : handleSubmit}
            mode={mode}
            isPending={isPending}
            showShadow={showBottomShadow}
            saveButtonText={`CONTROLS.${domainStatus.isValid ? 'SAVE' : 'PORTALS.SAVE_DRAFT'}`}
          />
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={checkFunction} />
          <OnChangeComponent field="fqdn" onChange={onFqdnChange} />
        </Form>
      )}
    </Formik>
  );
};

export default PortalForm;
