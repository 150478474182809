import transformMessage from './transformMessage';

export default ({ contentPattern }) => {
  if (contentPattern) {
    const channelKeys = Object.keys(contentPattern);
    let selectedType;

    switch (channelKeys.length) {
      case 4:
        selectedType = 'viberImageCard';
        break;
      case 3:
      case 2:
        if (contentPattern.imageUrl) {
          selectedType = 'viberImageCard';
        } else {
          selectedType = 'viberTextCard';
        }
        break;
      case 1: {
        if (channelKeys.includes('text')) {
          selectedType = 'viberText';
        } else {
          selectedType = 'viberImage';
        }
        break;
      }
      default: break;
    }

    return ({
      ...contentPattern,
      selectedType,
      buttonCaption: transformMessage(contentPattern.buttonCaption),
      message: transformMessage(contentPattern.text),
      image: {
        fileUrl: contentPattern.imageUrl,
      },
    });
  }

  return null;
};
