import React from 'react';
import { useTranslation } from 'react-i18next';
import { useKey } from 'react-use';

import Dialog from '@setproduct-ui/core/Dialog/Dialog';
import Button from '@setproduct-ui/core/Button';

import './style.scss';

// todo: refactor
//  remove unnecessary props
//  [iconColor, icon, color, leftText, rightText, title, text, rightButton, leftButton]
export default ({
  closeModal,
  onConfirm,
  isDelete,
  isPending,
  title = '',
  text = '',
  rightText = '',
  leftText = '',
  color = 'danger',
  icon = 'info-sign',
  iconColor = 'var(--red30)',
  rightButton,
  leftButton,
}) => {
  const dialogStyles = {
    title: {
      marginBottom: isDelete ? 0 : 16,
    },
    titleFont: isDelete ? {
      fontWeight: 500,
      fontSize: 20,
    } : {},
    container: {
      overflowY: 'unset',
    },
  };
  const { t } = useTranslation();

  useKey('Enter', () => {
    onConfirm();
  });
  useKey('Escape', () => {
    closeModal();
  });

  return (
    <Dialog
      type="default"
      view="raised"
      color={color}
      icon={icon}
      iconColor={iconColor}
      title={title || (isDelete ? t('CONFIRM_MODALS.DELETE_RECORD') : t('CONFIRM_MODALS.DATA_LOST'))}
      text={text || (isDelete ? '' : t('CONFIRM_MODALS.CLOSE_CONFIRM_DESC'))}
      backdropOpacity={40}
      isOpen
      onClose={closeModal}
      className="close-confirm-modal"
      withCloseButton={false}
      styles={dialogStyles}
      leftButton={leftButton || (
        <Button
          text={leftText || (isDelete ? t('CONTROLS.CANCEL') : t('CONTROLS.CONFIRM_MODALS.CLOSE_DECLINE'))}
          view="flat"
          color="danger"
          onClick={closeModal}
          disabled={isPending}
          data-testid={`${window.location.pathname}/confirm-modal-button/close`}
        />
      )}
      rightButton={rightButton || (
        <Button
          text={rightText || (isDelete ? t('CONTROLS.CONFIRM_MODALS.DELETE_CONFIRM') : t('CONTROLS.CONFIRM_MODALS.CLOSE_CONFIRM'))}
          view="smooth"
          color="danger"
          onClick={onConfirm}
          loading={isPending}
          data-testid={`${window.location.pathname}/confirm-modal-button/confirm`}
        />
      )}
    />
  );
};
