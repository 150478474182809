import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@blueprintjs/core';
import { useToggle } from 'react-use';

import Table from 'components/Table';
import ScrollableTabs from 'components/ScrollableTabs';
import { INVOICES } from 'consts/columns';
import { Pagination } from 'components/Pagination';
import SwitchField from 'components/fields/SwitchField';
import { useInvoicesApi, useDictionariesApi } from 'hooks/api';
import { useScreen } from 'hooks';
import { CustomColumnsView } from 'components/blocks';
import Button from '@setproduct-ui/core/Button';

import InvoiceDetails from './InvoiceDetails';
import ChargeDetails from './ChargeDetails';

import './style.scss';

const InvoicesScreen = ({ screenRef }) => {
  const { t } = useTranslation();
  const openTabsRef = useRef(null);
  const [isShowTotal, setIsShowTotal] = useState(false);
  const [isExpandedInfo, toggleIsExpandedInfo] = useToggle(false);
  const [openTabs, setOpenTabs] = useState([
    {
      title: 'Invoices and charges',
      withoutCross: true,
    },
  ]);
  const [activeTab, setActiveTab] = useState(0);
  const {
    getInvoices,
    isPendingGetInvoices,
    lastUpdatedGetInvoices,
    invoices,
    invoicesMeta,
  } = useInvoicesApi();
  const { getServicesDictionary, getCompaniesDictionary, getOwnLegalEntitiesDictionary } = useDictionariesApi();
  const {
    sendQuery,
    limit,
    offset,
    setOffset,
    setLimit,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    onSortChanged,
    onFilterChanged,
  } = useScreen({
    screenRef,
    getFunc: getInvoices,
    useNewFilters: true,
  });

  const openNewTab = ({ data, rowPinned }, parentIndex) => {
    if (rowPinned) {
      return;
    }

    let titlePrefix;
    switch (data.documentType) {
      case 0:
        titlePrefix = 'Invoice';
        break;
      case 1:
        titlePrefix = 'Credit note';
        break;
      default:
        titlePrefix = 'Charge';
        break;
    }
    const title = `${titlePrefix} ${data.referenceCode || `ID ${data.id}`}`;

    setOpenTabs((prev) => {
      const foundTabIndex = prev.findIndex(tab => tab.title === title);

      if (foundTabIndex !== -1) {
        setActiveTab(foundTabIndex);
        return prev;
      }

      // если это чардж
      if (parentIndex) {
        const prevModify = [...prev];
        let indexToInsert = parentIndex + 1;

        // если новая вкладка открывается не в конце
        if (prevModify.length !== indexToInsert) {
          // Проходимся по массиву начиная с таба после родителя (инвойса) в котором был вызван метод открытия таба
          for (let i = parentIndex + 1; i <= prevModify.length; i += 1) {
            indexToInsert = i;

            if (prev[i] && (prev[i].title.includes('Invoice') || prev[i].data.invoiceId !== data.invoiceId)) {
              // В случае если текущий таб (итерация) это инвойс или чардж, у которого инвойс айди отличается от того,
              // что мы открываем - останавливаем цикл и помещаем новооткрытый таб перед ним
              // все это нужно для группировки открытый чарджей по инвойсу
              break;
            }
          }
        }

        setActiveTab(indexToInsert);
        prevModify.splice(indexToInsert, 0, { title, data });

        return prevModify;
      }
      setActiveTab(prev.length);

      return ([
        ...prev,
        {
          title,
          data,
        },
      ]);
    });
  };
  const modifyTabData = (newData, title) => {
    setOpenTabs(prevState => prevState.map((tab) => {
      if (tab.title === title) {
        return ({
          title,
          data: { ...tab.data, ...newData },
        });
      }
      return tab;
    }));
  };
  const onTotalDueCellClick = (data) => {
    openNewTab({ data });
  };
  const onTabClose = (e, index) => {
    e.stopPropagation();
    const filteredTabs = [...openTabs];

    filteredTabs.splice(index, 1);

    setOpenTabs(filteredTabs);

    if (activeTab === index) {
      if (filteredTabs.length) {
        setActiveTab(filteredTabs.length - 1);
      } else {
        setActiveTab(0);
      }
    } else if (activeTab) {
      setActiveTab(activeTab - 1);
    }
  };
  const renderPanel = (tab, index) => {
    if (tab.title === 'Invoices and charges') {
      return (
        <div className="invoices-layout__table-wrapper">
          <Table
            // tableHeader="TABS.INVOICES"
            columnDefs={INVOICES(onTotalDueCellClick)}
            rowData={invoices}
            showFilters={false}
            // openCustomColumns={openCustomViewDrawer}
            rowHeight={28}
            filteredColumns={filteredColumns}
            sendQuery={sendQuery}
            isPending={isPendingGetInvoices || !lastUpdatedGetInvoices}
            tableRef={tableRef}
            onSortChanged={onSortChanged}
            onFilterChanged={onFilterChanged}
            withPagination={false}
            onRowDoubleClicked={openNewTab}
            pinnedBottomRowData={isShowTotal ? [{
              id: `${t('SCREENS.INVOICES.TOTAL')}:`,
              dueAmount: 3000,
              currency: 'USD',
            }] : undefined}
          />
          {isShowTotal && (
            <div className="invoices-layout__row">
              {`${t('SCREENS.INVOICES.TOTAL')}:`}
              <div className="invoices-layout__totals">
                1 405,99
                <span style={{ marginLeft: 6 }}>$ USD</span>
              </div>
              <div
                className="invoices-layout__toggle"
                role="presentation"
                onClick={toggleIsExpandedInfo}
              >
                {t(`SCREENS.INVOICES.${isExpandedInfo ? 'HIDE' : 'ALL'}_CURRENCIES`)}
                <Icon
                  icon={`chevron-${isExpandedInfo ? 'up' : 'down'}`}
                  style={{ marginLeft: 6 }}
                  size={16}
                  color="var(--grey70)"
                />
              </div>
            </div>
          )}
          <div className="invoices-layout__pagination">
            <Pagination
              limit={limit}
              total={invoicesMeta.size}
              page={offset}
              onChangePage={setOffset}
              onChangeLimit={setLimit}
              isPending={isPendingGetInvoices}
              onRefreshClick={sendQuery}
              leftComponent={5 < 2 && (
                <SwitchField
                  value={isShowTotal}
                  onChange={setIsShowTotal}
                  label="SCREENS.INVOICES.TOTAL"
                  color="primary"
                  styles={{ container: { marginRight: 24 } }}
                  dense
                />
              )}
            />
          </div>
        </div>
      );
    }
    if (tab.title.includes('Charge')) {
      return (
        <ChargeDetails
          modifyTabData={newData => modifyTabData(newData, tab.title)}
          openNewTab={openNewTab}
          {...tab.data}
        />
      );
    }
    return (
      <InvoiceDetails
        onRowDoubleClicked={params => openNewTab(params, index)}
        modifyTabData={newData => modifyTabData(newData, tab.title)}
        tabName={tab.title}
        tabData={tab.data}
        {...tab.data}
      />
    );
  };
  // for total due click (only initial state of openTabs accessible)
  useEffect(() => {
    openTabsRef.current = openTabs;
  }, [openTabs]);

  useEffect(() => {
    getServicesDictionary();
    getCompaniesDictionary();
    getOwnLegalEntitiesDictionary();
  }, []);

  return (
    <div className="invoices-layout">
      <ScrollableTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabsList={openTabs}
        onTabClose={onTabClose}
        styles={{ wrapper: { flex: 1 } }}
        renderPanel={renderPanel}
        elementInTabsPanel={(
          <Button
            type="default"
            view="outlined"
            color="primary"
            icon="add-column-right"
            onClick={openCustomViewDrawer}
            text="CONTROLS.COLUMNS"
            data-testid={`${window.location.pathname}/table/columns`}
            dense
          />
        )}
      />
      {
        isOpenCustomViewDrawer && (
          <CustomColumnsView
            initialValues={filteredColumns}
            columnsNames={INVOICES()}
            onSubmit={onSaveCustomView}
            onClose={closeCustomViewDrawer}
            isOpen={isOpenCustomViewDrawer}
          />
        )
      }
    </div>
  );
};

export default InvoicesScreen;
