import { saveAs } from 'file-saver';
import React, { useState, useRef } from 'react';
import { useToggle, useClickAway } from 'react-use';

import Table from 'components/Table';
import { useFilteredColumns, useToken } from 'hooks';
import { CustomColumnsView } from 'components/blocks';
import { Pagination } from 'components/Pagination';
import Button from '@setproduct-ui/core/Button/Button';
import endpoints from 'consts/endpoints';
import { FileDocument, FileExcel } from 'assets/icons';

import './style.scss';

const defaultOffset = 1;

export default ({
  postCreateReport,
  report,
  loading,
}) => {
  const paginationStyles = {
    container: {
      marginLeft: 'auto',
    },
  };

  const downloadOverlayRef = useRef();
  const [isDownloading, setIsDownloading] = useToggle(false);
  const [showDownloadDropdown, setDownloadDropdown] = useToggle(false);
  const [limit, setLimit] = useState(report.limit);
  const [offset, setOffset] = useState(
    report.offset ? (report.offset / report.limit) + 1 : defaultOffset,
  );
  const { token } = useToken();

  useClickAway(downloadOverlayRef, () => {
    setDownloadDropdown(false);
  });

  const {
    isOpenCustomViewDrawer,
    closeCustomViewDrawer,
    openCustomViewDrawer,
    onSaveCustomView,
    filteredColumns,
  } = useFilteredColumns();

  const getReport = (newLimit = report.limit, newOffset = report.offset) => {
    let valuesModify = {};

    Object.keys(report.parameters).forEach((key) => {
      valuesModify = {
        ...valuesModify,
        [key]: report.parameters[key] || '',
      };
    });

    postCreateReport({
      body: {
        name: report.name,
        params: valuesModify,
        templateId: report.templateId,
        limit: newLimit,
        offset: newOffset ? ((newOffset - 1) * newLimit) : newOffset,
      },
    });
  };

  const onChangeLimit = (newLimit) => {
    setLimit(newLimit);
    setOffset(defaultOffset);
    getReport(newLimit, defaultOffset);
  };

  const onChangeOffset = (newOffset) => {
    setOffset(newOffset);
    getReport(limit, newOffset);
  };

  const onPressDownloadFile = (type) => {
    setIsDownloading(true);
    setDownloadDropdown(false);
    const oReq = new XMLHttpRequest();
    oReq.open('POST', endpoints.getReportFileUrl(), true);
    oReq.responseType = 'blob';
    oReq.setRequestHeader('Authorization', `Bearer ${token}`);

    oReq.send(JSON.stringify({
      name: report.name,
      templateId: report.templateId,
      parameters: report.initialParameters,
      type,
    }));

    oReq.onload = () => {
      setIsDownloading(false);
      saveAs(
        oReq.response,
        `${report.name}.${type}`,
      );
    };
  };

  return (
    <div className="reports-table-layout">
      <Table
        columnDefs={report.columns}
        rowData={report.list}
        openCustomColumns={openCustomViewDrawer}
        filteredColumns={filteredColumns}
        withPagination={false}
      />
      <div className="reports-table-layout__footer">
        <div className="reports-table-layout__row" style={{ width: '100%' }}>
          <div
            ref={downloadOverlayRef}
            className="reports-table-layout__download-container"
          >
            {showDownloadDropdown && (
              <div className="reports-table-layout__file-dropdown">
                <div
                  role="presentation"
                  className="reports-table-layout__dropdown-item"
                  onClick={() => onPressDownloadFile('xls')}
                >
                  <FileExcel style={{ marginRight: 6 }} />
                  XLS file
                </div>
                <div
                  role="presentation"
                  className="reports-table-layout__dropdown-item"
                  onClick={() => onPressDownloadFile('csv')}
                >
                  <FileDocument style={{ marginRight: 6 }} />
                  CSV file
                </div>
              </div>
            )}
            <Button
              onClick={setDownloadDropdown}
              type="default"
              color="primary"
              // className="rates-layout__expand-button"
              icon="export"
              view="outlined"
              text="Export report as ..."
              loading={isDownloading}
              dense
            />
          </div>
          <Pagination
            total={report?.size}
            limit={limit}
            page={offset}
            onChangePage={onChangeOffset}
            onChangeLimit={onChangeLimit}
            onRefreshClick={getReport}
            isPending={loading}
            styles={paginationStyles}
          />
        </div>
      </div>
      {
        isOpenCustomViewDrawer && (
          <CustomColumnsView
            initialValues={filteredColumns}
            columnsNames={report.columns}
            onSubmit={onSaveCustomView}
            onClose={closeCustomViewDrawer}
            isOpen={isOpenCustomViewDrawer}
          />
        )
      }
    </div>
  );
};
