import React, { useMemo } from 'react';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import { isEqual, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  TextField,
  DropdownField,
  TextAreaField,
  CheckboxField,
  DatePickerField,
} from 'components/fields';
import { FormButtonsGroup } from 'components/blocks';
import { Typography } from 'components/Typography';
import FormContentWrapper from 'components/FormContentWrapper';
import {
  DIRECTIONS,
  inputPatterns,
} from 'consts';
import { PAYMENTS_SCHEMA } from 'consts/validationSchemas';
import { useDictionariesApi } from 'hooks/api';

import AmountWrapperField from './AmountWrapperField';
import ExchangeRateField from './ExchangeRateField';

import OnChangeComponent from '../OnChangeComponent';
import IsFormChanged from '../IsFormChanged';

import './style.scss';

export default ({
  initialValues = {},
  onSubmit,
  onCancel,
  onDelete,
  partnersOptions,
  agreementsAll,
  mode,
  changeMode,
  isPending,
  isFormPristine,
  formValuesRef,
}) => {
  // styles
  const fieldStyle = ({
    width = 268,
    marginBottom,
    marginRight,
    marginLeft,
    ...inputStyles
  } = {}) => ({
    container: {
      width,
      marginBottom,
      marginRight,
      marginLeft,
    },
    input: {
      ...inputStyles,
    },
  });
  const rateDateFieldStyle = {
    container: {
      width: 252,
      marginLeft: 'auto',
    },
  };
  // hooks
  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.states.routes);
  const { currencies } = useDictionariesApi();
  // memed vars
  const getAgreementsOptions = companyId => agreementsAll.reduce((acc, item) => {
    if (item.companyId === companyId) {
      acc.push({
        value: item.id,
        label: `${item.agreementCode} - (${item.currency})`,
      });
    }

    return acc;
  }, []);
  const isView = useMemo(() => mode === 'view', [mode]);
  const isEdit = useMemo(() => mode === 'edit', [mode]);
  // functions
  const changeToEdit = () => changeMode('edit');
  const onChangeClientId = ({ form }) => {
    if (form.dirty) {
      form.setFieldValue('agreementId', undefined);
    }
  };
  const onChangeAgreement = ({ value, values, form }) => {
    if (value) {
      const foundAccount = agreementsAll.find(item => item.id === value);

      if (foundAccount) {
        form.setFieldValue('accountCurrency', foundAccount.currency);

        if (!values.paymentCurrencyCode) {
          form.setFieldValue('paymentCurrencyCode', foundAccount.currency);
        }
      }
    }
  };
  const onChangePaymentCurrencyAmount = ({
    value,
    values,
    form,
  }) => {
    if (values.accountCurrency === values.paymentCurrencyCode) {
      form.setFieldValue('accountCurrencyAmount', value);
    } else if (values.accountCurrencyAmount) {
      form.setFieldValue('exchangeRate', (value / values.accountCurrencyAmount).toFixed(2));
    }
  };
  const onChangeAccountCurrencyAmount = ({
    value,
    values,
    form,
  }) => {
    if (values.paymentCurrencyAmount) {
      form.setFieldValue('exchangeRate', (values.paymentCurrencyAmount / value).toFixed(2));
    }
  };
  const onChangePaymentCurrencyCode = ({
    value,
    values,
    form,
  }) => {
    if (form.dirty && value === values.accountCurrency) {
      form.setFieldValue('accountCurrencyAmount', values.paymentCurrencyAmount);
    }
  };
  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }

    const valuesModify = { ...values };
    const initialValuesModify = { ...initialValues };

    switch (mode) {
      case 'edit':
        delete valuesModify.companyId;
        delete valuesModify.accountCurrency;
        delete valuesModify.exchangeRate;
        delete initialValuesModify.companyId;
        delete initialValuesModify.exchangeRate;
        return isEqual(valuesModify, initialValuesModify);
      case 'add':
        delete valuesModify.direction;
        return isEmpty(valuesModify) && values.direction === 0;
      default: return true;
    }
  };

  return (
    <Formik
      initialValues={{ direction: 0, ...initialValues }}
      onSubmit={onSubmit}
      validationSchema={PAYMENTS_SCHEMA}
      validateOnMount
      enableReinitialize
    >
      {({
        handleSubmit,
        values,
        errors,
        setFieldTouched,
      }) => (
        <Form className="payment-form">
          <FormContentWrapper>
            <div className="payment-form__row">
              <Field
                name="companyId"
                label="INSTANCES.COMPANY"
                component={DropdownField}
                options={partnersOptions}
                placeholder="PLACEHOLDERS.SELECT_COMPANY"
                styles={fieldStyle({ marginRight: 16 })}
                disabled={isView || isEdit}
                isRequired={!isView}
              />
              <Field
                name="agreementId"
                label="INSTANCES.AGREEMENT"
                component={DropdownField}
                options={getAgreementsOptions(values.companyId)}
                placeholder="PLACEHOLDERS.SELECT_ACCOUNT"
                styles={fieldStyle()}
                disabled={isView || !values.companyId || isEdit}
                isRequired={!isView}
              />
            </div>
            <div className="payment-form__row payment-form__row_block-closer">
              <Field
                name="date"
                label="INSTANCES.PAYMENTS.PAYMENT_DATE"
                component={DatePickerField}
                styles={fieldStyle({ marginRight: 16 })}
                disabled={isView}
                format="DD.MM.YYYY"
                isRequired={!isView}
              />
              <Field
                name="referenceCode"
                label="INSTANCES.PAYMENTS.REFERENCE_CODE"
                component={TextField}
                styles={fieldStyle()}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                disabled={isView}
                isRequired={!isView}
              />
            </div>
            <div className="payment-form__row">
              <Field
                name="direction"
                label="INSTANCES.DIRECTION"
                component={DropdownField}
                options={DIRECTIONS}
                styles={fieldStyle()}
                disabled={isView || isEdit}
              />
            </div>
            <div className="payment-form__row">
              <AmountWrapperField
                options={currencies}
                caretOptions={{
                  openColor: '#76A9FF',
                  closeColor: '#76A9FF',
                }}
                dropdownBackgroundColor="#EBF2FF"
                label="INSTANCES.PAYMENTS.PAYMENT_AMOUNT"
                textName="paymentCurrencyAmount"
                dropdownName="paymentCurrencyCode"
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                isView={isView}
                style={{ marginRight: 16 }}
                regex={inputPatterns.decimal({ decimalLimit: 2 })}
              />
              <AmountWrapperField
                options={currencies}
                caretOptions={{
                  visible: false,
                }}
                dropdownBackgroundColor="#F7F7FA"
                label="INSTANCES.PAYMENTS.REPLENISHMENT_AMOUNT"
                textName="accountCurrencyAmount"
                dropdownName="accountCurrency"
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                disableDropdown
                isView={isView}
                disableInput={values.accountCurrency === values.paymentCurrencyCode}
                regex={inputPatterns.decimal({ decimalLimit: 2 })}
              />
            </div>
            {
              (values.accountCurrency !== values.paymentCurrencyCode) && (
                <div className="payment-form__exchange">
                  <Typography className="payment-form__exchange__title">
                    SCREENS.PAYMENTS.CURRENCY_EXCHANGE
                  </Typography>
                  <div className="payment-form__row payment-form__row_block-closer">
                    <ExchangeRateField
                      options={currencies}
                      label="INSTANCES.PAYMENTS.EXCHANGE_RATE"
                      name="exchangeRate"
                      accountCurrency={values.accountCurrency}
                      currency={values.paymentCurrencyCode}
                      disabled={isView}
                    />
                    <Field
                      name="exchangeRateDate"
                      label="INSTANCES.PAYMENTS.EXCHANGE_RATE_DATE"
                      component={DatePickerField}
                      styles={rateDateFieldStyle}
                      disabled={isView}
                      format="DD.MM.YYYY"
                    />
                  </div>
                </div>
              )
            }
            <div className="payment-form__row payment-form__row_block-closer">
              <Field
                name="description"
                label="INSTANCES.DESCRIPTION"
                component={TextAreaField}
                placeholder="PLACEHOLDERS.ENTER_DESCRIPTION"
                disabled={isView}
                minRows={2}
              />
            </div>
            <div className="payment-form__row">
              <Field
                name="isConfirmed"
                label="Deferred payment"
                component={CheckboxField}
                text="INSTANCES.PAYMENTS.PAYMENT_CONFIRMED"
                disabled={isView}
              />
              {
                !values?.isConfirmed && (
                  <Field
                    name="expiryDate"
                    label="INSTANCES.PAYMENTS.CONFIRM_PAYMENT_BEFORE"
                    component={DatePickerField}
                    format="DD.MM.YYYY"
                    styles={fieldStyle({ marginLeft: 'auto' })}
                    disabled={isView}
                  />
                )
              }
            </div>
          </FormContentWrapper>
          <FormButtonsGroup
            onDelete={onDelete}
            onCancel={onCancel}
            onApply={isView ? changeToEdit : handleSubmit}
            mode={mode}
            isPending={isPending}
            setFieldTouched={setFieldTouched}
            errors={errors}
          />
          <OnChangeComponent field="companyId" onChange={onChangeClientId} />
          <OnChangeComponent field="agreementId" onChange={onChangeAgreement} />
          <OnChangeComponent field="paymentCurrencyAmount" onChange={onChangePaymentCurrencyAmount} />
          <OnChangeComponent field="accountCurrencyAmount" onChange={onChangeAccountCurrencyAmount} />
          <OnChangeComponent field="paymentCurrencyCode" onChange={onChangePaymentCurrencyCode} />
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={checkFunction} />
        </Form>
      )}
    </Formik>
  );
};
