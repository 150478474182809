import React, {
  useRef, useMemo,
} from 'react';
import { Formik, Form, Field } from 'formik';
import { isEqual, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@blueprintjs/core';

import {
  TextField,
  DropdownField,
  CheckboxField,
} from 'components/fields';
import Button from '@setproduct-ui/core/Button';
import Modal from 'components/Modal';
import { FormButtonsGroup } from 'components/blocks';
import { useContentShadow, useModalLogic } from 'hooks';
import { useUsersApi, useDictionariesApi } from 'hooks/api';
import IsFormChanged from 'components/forms/IsFormChanged';
import OnChangeComponent from 'components/forms/OnChangeComponent';
import { LEGAL_ENTITY_SCHEMA, EXPAND_LEGAL_ENTITY_SCHEMA } from 'consts/validationSchemas';

import AgreementsTable from './AgreementsTable';

import './style.scss';

const LegalEntityForm = ({
  changeMode,
  mode,
  onSubmit,
  initialValues = {
    isDefault: false,
  },
  onDelete,
  onCancel,
  isPending,
  isFormPristine,
  formValuesRef,
  partnersOptions,
  isOwnLegalEntities,
}) => {
  const fieldStyle = ({
    width, marginTop, marginBottom, ...inputStyles
  } = {}) => ({
    container: {
      width,
      marginBottom,
    },
    label: {
      marginTop,
    },
    input: {
      ...inputStyles,
    },
  });

  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.states.routes);
  const contentRef = useRef(null);
  const {
    showTopShadow,
    showBottomShadow,
  } = useContentShadow(contentRef);
  const { currentUser } = useUsersApi();
  const { portalsOptions } = useDictionariesApi();
  const [isOpenModal, { openModal, closeModal }] = useModalLogic(false);

  const isView = useMemo(() => mode === 'view', [mode]);
  const validationSchema = useMemo(() => {
    if (isOwnLegalEntities) {
      return LEGAL_ENTITY_SCHEMA;
    }
    return LEGAL_ENTITY_SCHEMA.concat(EXPAND_LEGAL_ENTITY_SCHEMA);
  }, [isOwnLegalEntities]);
  const changeToEdit = () => changeMode('edit');
  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }

    switch (mode) {
      case 'edit':
        return isEqual(values, initialValues);
      case 'add':
        return isEmpty(values);
      default: return true;
    }
  };
  const onChangeCompanyId = ({ form }) => {
    if (form.dirty) {
      form.setFieldValue('portalId', undefined);
    }
  };
  const getPortalsOptions = companyId => portalsOptions
    .filter(item => item.companyId === (companyId || currentUser?.companyId));

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={body => onSubmit({
        ...body,
        companyId: isOwnLegalEntities ? currentUser?.companyId : body.companyId,
      })}
    >
      {({
        handleSubmit,
        errors,
        setFieldTouched,
        values,
      }) => (
        <Form className="legal-entity-form">
          {showTopShadow && <div className="legal-entity-form__shadow" />}
          <div className="legal-entity-form__content" ref={contentRef}>
            <Field
              name="name"
              component={TextField}
              label="INSTANCES.NAME"
              disabled={isView}
              isRequired={!isView}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              full
            />
            {!isOwnLegalEntities && (
              <Field
                name="companyId"
                component={DropdownField}
                label="INSTANCES.LEGAL_ENTITIES.COMPANY"
                options={partnersOptions}
                disabled={isView}
                isRequired={!isView}
                styles={fieldStyle({ marginTop: 24 })}
                placeholder="PLACEHOLDERS.SELECT"
              />
            )}
            <Field
              name="legalCompanyName"
              component={TextField}
              label="INSTANCES.LEGAL_ENTITIES.LEGAL_COMPANY_NAME"
              disabled={isView}
              isRequired={!isView}
              styles={fieldStyle({ marginTop: 24 })}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              full
            />
            <Field
              name="portalId"
              component={DropdownField}
              label="INSTANCES.LEGAL_ENTITIES.PORTAL"
              options={getPortalsOptions(values.companyId)}
              disabled={isView || (!isOwnLegalEntities && !values.companyId)}
              styles={fieldStyle({ marginTop: 24 })}
              placeholder="PLACEHOLDERS.SELECT"
            />
            <Field
              name="taxIdentificationNo"
              component={TextField}
              label="INSTANCES.LEGAL_ENTITIES.TAX_ID_NUM"
              disabled={isView}
              styles={fieldStyle({ marginTop: 24 })}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              full
            />
            <Field
              name="legalAddress"
              component={TextField}
              label="INSTANCES.LEGAL_ENTITIES.LEGAL_ADDRESS"
              disabled={isView}
              styles={fieldStyle({ marginTop: 24 })}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              full
            />
            <Field
              name="billingAddress"
              component={TextField}
              label="INSTANCES.LEGAL_ENTITIES.BILLING_ADDRESS"
              disabled={isView}
              styles={fieldStyle({ marginTop: 24 })}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              full
            />
            <Field
              name="isDefault"
              component={CheckboxField}
              text="INSTANCES.LEGAL_ENTITIES.IS_DEFAULT"
              styles={fieldStyle({ marginTop: 24 })}
              disabled={isView}
            />
            {mode !== 'add' && (
              <Button
                onClick={openModal}
                text="CONTROLS.LEGAL_ENTITIES.MANAGE_AGREEMENTS"
                color="primary_alt"
                view="outlined"
                style={{ marginTop: 24, minHeight: 40 }}
              />
            )}
          </div>
          <FormButtonsGroup
            onDelete={onDelete}
            onCancel={onCancel}
            onApply={isView ? changeToEdit : handleSubmit}
            mode={mode}
            errors={errors}
            setFieldTouched={setFieldTouched}
            isPending={isPending}
            showShadow={showBottomShadow}
          />
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={checkFunction} />
          {isOpenModal && (
            <Modal
              closeModal={closeModal}
              withTitle={false}
              withCloseButton={false}
              styles={{
                container: { padding: 24 },
              }}
              className="legal-entity-form__modal"
            >
              <div className="legal-entity-form__modal-header">
                <div className="legal-entity-form__modal-title">
                  Reselling agreements for legal entity
                  <b>{initialValues.name}</b>
                </div>
                <Icon
                  icon="cross"
                  onClick={closeModal}
                  size={20}
                />
              </div>
              <AgreementsTable
                legalEntityId={initialValues.id}
                companyId={initialValues.companyId}
                isOwnLegalEntities={isOwnLegalEntities}
              />
            </Modal>
          )}
          <OnChangeComponent field="companyId" onChange={onChangeCompanyId} />
        </Form>
      )}
    </Formik>
  );
};

export default LegalEntityForm;
