import React from 'react';

import { NotificationSettings } from 'assets/icons';

import paths from './paths';

import * as screens from '../screens';

export const accountManagementRoutes = [
  {
    exact: true,
    path: paths.companies,
    name: 'TABS.COMPANIES',
    Component: screens.CompaniesScreen,
  },
  // {
  //   exact: true,
  //   name: 'TABS.ACCOUNTS',
  //   path: paths.accountsMain,
  //   Component: screens.AccountsScreen,
  // },
  {
    exact: true,
    path: paths.legalEntities,
    name: 'TABS.LEGAL_ENTITIES',
    Component: screens.LegalEntitiesScreen,
  },
  {
    exact: true,
    path: paths.agreements,
    name: 'TABS.AGREEMENTS',
    Component: screens.AgreementsScreen,
  },
  {
    exact: true,
    path: paths.servicesEnabled,
    name: 'TABS.SERVICES_ENABLED',
    Component: screens.ServicesEnabledScreen,
  },
  {
    exact: true,
    path: paths.packs,
    name: 'TABS.PACKS_ENABLED',
    Component: screens.PacksScreen,
  },
  {
    exact: true,
    path: paths.senderIds,
    name: 'TABS.SENDER_IDS',
    Component: screens.SenderIdsScreen,
  },
  {
    exact: true,
    path: paths.waba,
    name: 'TABS.WABA',
    Component: screens.WabaScreen,
  },
  {
    exact: true,
    path: paths.whatsappTemplates,
    name: 'TABS.WHATSAPP_TEMPLATES',
    Component: screens.WhatsAppTemplatesScreen,
  },
  {
    exact: true,
    path: paths.applications,
    name: 'TABS.APPLICATIONS',
    Component: screens.ApplicationsScreen,
  },
  {
    exact: true,
    path: paths.printableForms,
    name: 'TABS.PRINTABLE_FORMS',
    Component: screens.PrintableFormsScreen,
  },
];

export const financeManagementRoutes = [
  {
    exact: true,
    path: paths.payments,
    name: 'TABS.PAYMENTS',
    Component: screens.PaymentsScreen,
  },
  {
    exact: true,
    path: paths.invoices,
    name: 'TABS.INVOICES',
    Component: screens.InvoicesScreen,
  },
  {
    exact: true,
    path: paths.services,
    name: 'TABS.SERVICES',
    Component: screens.ServicesScreen,
  },
];

export const usersRoutes = [
  {
    exact: true,
    path: paths.backOfficeUsers,
    name: 'TABS.BACKOFFICE_USERS',
    Component: screens.BackOfficeUsersScreen,
  },
  {
    exact: true,
    path: paths.mKitUsers,
    name: 'TABS.MKIT_USERS',
    Component: screens.MKitUsersScreen,
  },
];

export const settingsRoutes = [
  {
    exact: true,
    path: paths.settings,
    name: 'TABS.GENERAL_SETTINGS',
    Component: screens.SettingsScreen,
    isForTenantOnly: true,
  },
  {
    exact: true,
    path: paths.smppConnections,
    name: 'TABS.SMPP_CONNECTIONS',
    Component: screens.SmppConnectionsScreen,
    isForTenantOnly: true,
  },
  {
    exact: true,
    path: paths.httpConnections,
    name: 'TABS.HTTP_CONNECTIONS',
    Component: screens.HttpConnectionsScreen,
    isForTenantOnly: true,
  },
  {
    exact: true,
    path: paths.ownLegalEntities,
    name: 'TABS.OWN_LEGAL_ENTITIES',
    Component: screens.LegalEntitiesScreen,
  },
  {
    exact: true,
    path: paths.notificationsChannelsSettings,
    name: 'TABS.NOTIFICATIONS_CHANNELS_SETTINGS',
    Component: screens.NotificationsChannelsScreens,
    isForTenantOnly: true,
  },
];

const routes = [
  {
    icon: 'people',
    name: 'TABS.CLIENTS_MANAGEMENT',
    exact: true,
    path: paths.clients,
    Component: screens.ClientsScreen,
  },
  {
    icon: 'briefcase',
    name: 'TABS.ACCOUNT_MANAGEMENT',
    exact: true,
    path: paths.companies,
    children: accountManagementRoutes,
    Component: screens.CompaniesScreen,
  },
  {
    icon: 'badge',
    name: 'TABS.FINANCES',
    exact: false,
    path: paths.payments,
    children: financeManagementRoutes,
    Component: screens.PaymentsScreen,
  },
  {
    exact: true,
    icon: 'learning',
    path: paths.mccMnc,
    name: 'TABS.MCCMNC',
    Component: screens.MccMncScreen,
  },
  {
    exact: false,
    icon: 'user',
    name: 'TABS.USERS',
    path: paths.backOfficeUsers,
    children: usersRoutes,
    Component: screens.BackOfficeUsersScreen,
  },
  {
    icon: 'series-search',
    name: 'TABS.REPORTS_AND_ANALYTICS',
    exact: true,
    path: paths.reports,
    Component: screens.ReportsScreen,
  },
  {
    exact: true,
    iconComponent: <NotificationSettings />,
    path: paths.notificationsSettings,
    name: 'TABS.NOTIFICATIONS_SETTINGS',
    Component: screens.NotificationsScreens,
    isForTenantOnly: true,
  },
  {
    exact: false,
    icon: 'cog',
    name: 'TABS.SETTINGS',
    path: paths.settings,
    children: settingsRoutes,
    Component: screens.SettingsScreen,
  },
  {
    exact: false,
    icon: 'manually-entered-data',
    name: 'TABS.AUDIT',
    path: paths.audit,
    Component: screens.AuditScreen,
  },
  {
    icon: 'eye-on',
    name: 'TABS.PRE_MODERATION',
    exact: false,
    path: paths.preModeration,
    Component: screens.PreModerationScreen,
  },
  {
    icon: 'projects',
    name: 'TABS.PORTALS',
    exact: false,
    path: paths.portals,
    Component: screens.PortalsScreen,
  },
];

export default routes;
