import React, { useState } from 'react';
import { Field } from 'formik';
import { Tab, Icon } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { Typography } from 'components/index';
import DropdownField from 'components/fields/DropdownField';
import TextField from 'components/fields/TextField';
import Callout from '@setproduct-ui/core/Callout';
import Tabs from '@setproduct-ui/core/Tabs';
import {
  SENDER_STATUSES,
  RCS_VENDOR_TYPES,
} from 'consts';
import { generateUuid } from 'helpers';

import VisualSettings from './VisualSettings';
import JSONFileField from './JSONFileField';
import GSMAFields from './GSMAFields';
import styles from './RcsContent.module.scss';

const RcsContent = ({
  values,
  partnersOptions,
  initialValues,
  senderValues,
  readOnly,
  highlightChanged,
  showTabs,
  errors,
  setFieldValue,
  generateButtonStyles,
  dropdownFieldStyles,
  getAgreementsOptions,
  channelsOptions,
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('display');

  const generalInfoClass = cx(styles.generalInfo, {
    [styles.generalInfo_withBorder]: readOnly && !showTabs,
  });

  return (
    <>
      <div className={generalInfoClass}>
        <Field
          name="companyId"
          component={DropdownField}
          options={partnersOptions}
          styles={dropdownFieldStyles({ marginRight: (readOnly && !showTabs) ? 24 : 12 })}
          label="INSTANCES.COMPANY"
          placeholder="PLACEHOLDERS.SELECT_PARTNER"
          disabled={readOnly}
          isRequired
        />
        {
          (!readOnly || showTabs) && (
          <Field
            name="agreementId"
            component={DropdownField}
            label="INSTANCES.AGREEMENT"
            placeholder="PLACEHOLDERS.SELECT"
            options={getAgreementsOptions(values.companyId)}
            styles={dropdownFieldStyles({ marginLeft: 12, marginRight: 24 })}
            disabled={readOnly || !values.companyId}
            isRequired
          />
          )
        }
        <DropdownField
          options={channelsOptions}
          value={2}
          styles={dropdownFieldStyles(
            { marginLeft: 24, marginRight: (readOnly && !showTabs) ? 0 : 12 },
          )}
          label="INSTANCES.CHANNEL"
          placeholder="PLACEHOLDERS.SELECT"
          disabled
          isRequired
        />
        {
          (!readOnly || showTabs) && (
          <Field
            name="senderStatus"
            component={DropdownField}
            label="INSTANCES.STATUS"
            placeholder="PLACEHOLDERS.SELECT"
            options={SENDER_STATUSES}
            styles={dropdownFieldStyles({ marginLeft: 12 })}
            disabled={readOnly}
            isRequired
          />
          )
        }
      </div>
      {
        readOnly && !showTabs ? (
          <VisualSettings
            values={values}
            errors={errors}
            style={{ maxHeight: 'calc(100vh - 215px)' }}
            readOnly={readOnly}
            showTabs={showTabs}
            initialValues={initialValues}
            highlightChanged={highlightChanged}
            senderValues={senderValues}
            styles={styles}
          />
        ) : (
          <Tabs
            type="def"
            view="flat"
            id="application-tabs"
            fill
            color="primary"
            className={styles.tabs}
            selectedTabId={activeTab}
            onChange={tabId => setActiveTab(tabId)}
          >
            <Tab
              id="display"
              title={t('SCREENS.SENDER_IDS.PROPERTIES')}
              panel={(
                <VisualSettings
                  values={values}
                  errors={errors}
                  readOnly={readOnly}
                  showTabs={showTabs}
                  initialValues={initialValues}
                  highlightChanged={highlightChanged}
                  senderValues={senderValues}
                  styles={styles}
                />
              )}
            />
            <Tab
              id="tech"
              title={t('SCREENS.SENDER_IDS.CONFIGURATION')}
              panel={(
                <div className={styles.content}>
                  <div className={styles.column}>
                    <Typography className={cx(styles.subtitle, styles.subtitle_required)}>
                      INSTANCES.SENDER_IDS.WEBHOOK_GUID
                    </Typography>
                    <Field
                      name="webHookGuid"
                      component={TextField}
                      rightElement={!values.webHookGuid && !readOnly && (
                        <Icon
                          icon="new-link"
                          size={20}
                          color="var(--blue70)"
                          style={generateButtonStyles}
                          onClick={() => setFieldValue('webHookGuid', generateUuid())}
                        />
                      )}
                      disabled={readOnly}
                      placeholder="PLACEHOLDERS.ENTER_VALUE"
                      full
                    />
                    <div className={styles.horDivider} />
                    <Typography className={cx(styles.subtitle, styles.subtitle_required)}>
                      INSTANCES.VENDOR_TYPE
                    </Typography>
                    <Field
                      name="vendorType"
                      component={DropdownField}
                      options={RCS_VENDOR_TYPES}
                      placeholder="PLACEHOLDERS.SELECT"
                      disabled={readOnly}
                    />
                    {values.vendorType === 'alaris_gsma' && (
                    <GSMAFields readOnly={readOnly} />
                    )}
                    {values.vendorType === 'google_rbm' && (
                    <>
                      <Typography
                        className={cx(styles.subtitle, styles.subtitle_required)}
                        style={{ marginTop: 24 }}
                      >
                        SCREENS.SENDER_IDS.API_ACCESS
                      </Typography>
                      <Field
                        component={JSONFileField}
                        disabled={readOnly}
                        name="vendorConfig"
                      />
                    </>
                    )}
                  </div>
                  <div className={styles.column}>
                    <Callout
                      view="smooth"
                      title={t('INSTANCES.SENDER_IDS.WEBHOOK_GUID')}
                      icon={<Icon icon="info-sign" color="var(--grey30)" size={20} />}
                      className={styles.callout}
                      style={{ marginBottom: 24 }}
                      dense
                    >
                      {t('SCREENS.SENDER_IDS.WEBHOOK_DESC')}
                    </Callout>
                    {
                      values.vendorType === 'google_rbm' && (
                      <Callout
                        view="smooth"
                        title={t('SCREENS.SENDER_IDS.RBM_CONFIG')}
                        icon={<Icon icon="info-sign" color="var(--grey30)" size={20} />}
                        className={styles.callout}
                        dense
                      >
                        {t('SCREENS.SENDER_IDS.RBM_CONFIG_DESC')}
                        <a href="https://google.com" target="_blank" rel="noreferrer noopener">
                          {`\n${window.location.origin.replace('-admin', '')}/google/sync?bot_id=${values.webHookGuid}`}
                        </a>
                        {t('SCREENS.SENDER_IDS.RBM_CONFIG_DESC_END')}
                      </Callout>
                      )
                    }
                    {
                      values.vendorType === 'alaris_gsma' && (
                      <Callout
                        view="smooth"
                        title={t('SCREENS.SENDER_IDS.GSMA_CONFIG')}
                        icon={<Icon icon="info-sign" color="var(--grey30)" size={20} />}
                        className={styles.callout}
                        dense
                      >
                        {t('SCREENS.SENDER_IDS.GSMA_CONFIG_DESC')}
                        <a href="https://google.com" target="_blank" rel="noreferrer noopener">
                          {`\n${window.location.origin.replace('-admin', '')}/google/sync?bot_id=${values.webHookGuid}`}
                        </a>
                      </Callout>
                      )
                    }
                  </div>
                </div>
              )}
            />
          </Tabs>
        )
      }
    </>
  );
};

export default RcsContent;
