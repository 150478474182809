import _ from 'lodash';

import endpoints from 'consts/endpoints';

export default () => ({
  url: endpoints.getTimezonesUrl(),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: response => ({
    timezones: _.sortBy(response.map(({ id, areaName, areaOffset }) => ({
      label: `${areaName} ${areaOffset}`,
      areaName,
      value: id,
    })), ['label']),
  }),
  update: {
    timezones: (oldValue, newValue) => newValue,
  },
});
