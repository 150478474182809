import moment from 'moment';

import {
  DIRECTIONS,
  BILLING_PERIOD,
  BLOCKED,
  CONFIRMED,
  CHANNELS_DICTIONARY,
  CHANNELS_OPTIONS,
  APPLICATION_STATUSES_OPTIONS,
  APPLICATION_STATUSES,
  APPLICATION_TYPES,
  SENDER_STATUSES,
  AGREEMENT_DIRECTIONS,
  INVOICE_DIRECTIONS,
  INVOICE_STATUSES,
  INVOICE_DISPATCH_STATUSES,
  INVOICE_DISPUTE_STATUSES,
  INVOICE_PAYMENT_STATUSES,
  CHARGE_SOURCES,
  CHARGE_DUE_SOURCES,
  SMS_VENDOR_TYPES,
  RCS_VENDOR_TYPES,
  WHATSAPP_VENDOR_TYPES,
  SERVICE_TYPES_DICTIONARY,
  SERVICE_TYPES_OPTIONS,
  VIBER_VENDOR_TYPES_OPTIONS,
  HTTP_VENDOR_TYPES_DICTIONARY,
  HTTP_VENDOR_TYPES_OPTIONS,
  MODERATION_STATUSES,
  CHANNELS_STRING_VALUES,
  PORTALS_STATUSES_OPTIONS,
  PORTALS_STATUSES_DICTIONARY,
  VK_OK_VENDOR_TYPES_OPTIONS,
  WHATSAPP_TEMPLATES_STATUSES,
  MODERATION_CONTENT_TYPES_DICTIONARY,
  MODERATION_CONTENT_TYPES_OPTIONS,
} from './index';

const formatDate = (value, format = 'DD.MM.YYYY') =>
  (value ? moment(value).format(format) : '');
const parseDate = ({ value }) => formatDate(value);
const parseDateWithTime = ({ value }) => formatDate(value, 'DD.MM.YYYY HH:mm');
const parseConstOptions = (options, value) =>
  options.find(I => I.value === value)?.label;
const cellStyle = (params) => {
  if (params.column.filterActive || params.column.sort) {
    return { backgroundColor: 'var(--grey0)' };
  }
  return {};
};

const MCC_MNC_COLUMNS = [
  {
    headerName: 'INSTANCES.MCCMNC',
    field: 'mccMnc',
    flex: 1,
    filter: 'numberColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.NETWORK',
    field: 'networkName',
    flex: 1,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.COUNTRY',
    field: 'countryName',
    flex: 1,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.COUNTRY',
    field: 'countryDialCode',
    flex: 1,
    filter: 'numberColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.START_DATE',
    field: 'startDate',
    flex: 1,
    valueFormatter: parseDate,
    filter: 'dateColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.END_DATE',
    field: 'endDate',
    flex: 1,
    valueFormatter: parseDate,
    filter: 'dateColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.DESCRIPTION',
    field: 'description',
    flex: 1,
    filter: 'maskColumnFilter',
    cellStyle,
  },
];
const DIAL_CODES = [
  {
    headerName: 'INSTANCES.MCCMNC',
    field: 'mccMnc',
    flex: 1,
    filter: 'numberColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.PREFIXES.PREFIX',
    field: 'dialCode',
    flex: 1,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.START_DATE',
    field: 'startDate',
    flex: 1,
    valueFormatter: parseDate,
    filter: 'dateColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.END_DATE',
    field: 'endDate',
    flex: 1,
    valueFormatter: parseDate,
    filter: 'dateColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.DESCRIPTION',
    field: 'description',
    flex: 1,
    filter: 'maskColumnFilter',
    cellStyle,
  },
];
const COMPANIES = [
  {
    headerName: 'INSTANCES.ID',
    field: 'id',
    flex: 1,
    minWidth: 80,
    sortable: true,
    filter: 'numberColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.COMPANY',
    field: 'name',
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.OWN_LEGAL_ENTITY',
    field: 'parentCompanyLEId',
    flex: 1,
    minWidth: 110,
    sortable: true,
    filter: 'listColumnFilter',
    headerComponentParams: {
      reservedList: 'ownLegalEntities',
    },
    cellRenderer: 'cellRendererDictionaryMap',
    cellRendererParams: {
      dictionaryKey: 'ownLegalEntitiesDictionary',
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.COMPANIES.IS_RESELLER',
    field: 'isReseller',
    flex: 1,
    sortable: true,
    filter: 'listColumnFilter',
    headerComponentParams: {
      items: [
        {
          label: 'True',
          value: true,
        },
        {
          label: 'False',
          value: false,
        },
      ],
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.COMPANIES.RESELLERS_ALLOWED',
    field: 'ownResellersAllowed',
    flex: 1,
    sortable: true,
    filter: 'listColumnFilter',
    headerComponentParams: {
      items: [
        {
          label: 'True',
          value: true,
        },
        {
          label: 'False',
          value: false,
        },
      ],
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.COMPANIES.MODERATION',
    field: 'isModerated',
    flex: 1,
    sortable: true,
    filter: 'listColumnFilter',
    headerComponentParams: {
      items: [
        {
          label: 'True',
          value: true,
        },
        {
          label: 'False',
          value: false,
        },
      ],
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CREATED',
    field: 'createdAt',
    flex: 1,
    valueFormatter: parseDate,
    filter: 'dateColumnFilter',
    sortable: true,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.UPDATED',
    field: 'updatedAt',
    flex: 1,
    valueFormatter: parseDate,
    filter: 'dateColumnFilter',
    sortable: true,
    cellStyle,
  },
];
const USERS = userCompanyId => [
  {
    headerName: 'INSTANCES.ID',
    field: 'id',
    flex: 1,
    minWidth: 110,
    sortable: true,
    filter: 'numberColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.COMPANY',
    field: 'companyId',
    flex: 1,
    minWidth: 180,
    sortable: true,
    filter: 'listColumnFilter',
    headerComponentParams: {
      reservedList: 'companiesWithOwn',
    },
    valueFormatter: ({ data }) => data?.companyName,
    cellRenderer: 'cellRendererCompanyWithChip',
    cellStyle: params => ({
      ...cellStyle(params),
      fontWeight: params.data.companyId === userCompanyId ? 'bold' : '',
    }),
  },
  {
    headerName: 'INSTANCES.LOGIN',
    field: 'username',
    flex: 1,
    minWidth: 110,
    sortable: true,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.USERS.FIRST_NAME',
    field: 'firstName',
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.USERS.MIDDLE_NAME',
    field: 'middleName',
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.USERS.LAST_NAME',
    field: 'lastName',
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CREATED',
    field: 'createdAt',
    flex: 1,
    valueFormatter: parseDate,
    sortable: true,
    filter: 'dateColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.JOB_TITLE',
    field: 'jobTitle',
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.PHONE_NUMBER',
    field: 'phoneNumber',
    flex: 1,
    sortable: true,
    filter: 'numberColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.EMAIL',
    field: 'email',
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.USERS.LAST_LOGIN',
    field: 'updatedAt',
    flex: 1,
    valueFormatter: parseDate,
    sortable: true,
    filter: 'dateColumnFilter',
    cellStyle,
  },
];
const MKIT_USERS = userCompanyId => [
  {
    headerName: 'INSTANCES.ID',
    field: 'id',
    flex: 1,
    minWidth: 110,
    sortable: true,
    filter: 'numberColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.COMPANY',
    field: 'companyId',
    flex: 1,
    minWidth: 180,
    sortable: true,
    filter: 'listColumnFilter',
    headerComponentParams: {
      reservedList: 'companiesWithOwn',
    },
    valueFormatter: ({ data }) => data?.companyName,
    cellRenderer: 'cellRendererCompanyWithChip',
    cellStyle: params => ({
      ...cellStyle(params),
      fontWeight: params.data.companyId === userCompanyId ? 'bold' : '',
    }),
  },
  {
    headerName: 'INSTANCES.LOGIN',
    field: 'username',
    flex: 1,
    minWidth: 110,
    sortable: true,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.USERS.FIRST_NAME',
    field: 'firstName',
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.USERS.MIDDLE_NAME',
    field: 'middleName',
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.USERS.LAST_NAME',
    field: 'lastName',
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CREATED',
    field: 'createdAt',
    flex: 1,
    valueFormatter: parseDate,
    sortable: true,
    filter: 'dateColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.JOB_TITLE',
    field: 'jobTitle',
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.PHONE_NUMBER',
    field: 'phoneNumber',
    flex: 1,
    sortable: true,
    filter: 'numberColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.EMAIL',
    field: 'email',
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.USERS.LAST_LOGIN',
    field: 'updatedAt',
    flex: 1,
    valueFormatter: parseDate,
    sortable: true,
    filter: 'dateColumnFilter',
    cellStyle,
  },
];
const AGREEMENTS = [
  {
    headerName: 'INSTANCES.ID',
    field: 'id',
    flex: 1,
    minWidth: 100,
    sortable: true,
    filter: 'numberColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.COMPANY',
    field: 'companyName',
    flex: 1,
    minWidth: 115,
    sortable: true,
    filter: 'listColumnFilter',
    headerComponentParams: {
      reservedList: 'companies',
      filterKey: 'companyId',
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.LEGAL_ENTITY',
    field: 'legalEntityName',
    flex: 1,
    minWidth: 131,
    sortable: true,
    filter: 'listColumnFilter',
    headerComponentParams: {
      reservedList: 'legalEntities',
      filterKey: 'legalEntityId',
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.OWN_LEGAL_ENTITY',
    field: 'parentCompanyLegalEntityName',
    flex: 1,
    minWidth: 165,
    sortable: true,
    filter: 'listColumnFilter',
    headerComponentParams: {
      reservedList: 'ownLegalEntities',
      filterKey: 'parentCompanyLegalEntityId',
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.AGREEMENTS.AGREEMENT_CODE',
    field: 'agreementCode',
    flex: 1,
    minWidth: 165,
    sortable: true,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.AGREEMENTS.AGREEMENT_DATE',
    field: 'agreementDate',
    flex: 1,
    minWidth: 165,
    valueFormatter: parseDate,
    sortable: true,
    filter: 'dateColumnFilter',
    headerComponentParams: {
      startWithTime: false,
      endWithTime: false,
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.DIRECTION',
    field: 'direction',
    flex: 1,
    minWidth: 120,
    sortable: true,
    headerComponentParams: {
      items: AGREEMENT_DIRECTIONS,
    },
    filter: 'listColumnFilter',
    valueFormatter: ({ value }) => {
      switch (value) {
        case 0: return ({
          tag: 'INSTANCES.AGREEMENTS.CLIENT',
          color: 'success',
        });
        case 1: return ({
          tag: 'INSTANCES.AGREEMENTS.VENDOR',
          color: 'primary',
        });
        default: return {};
      }
    },
    cellRenderer: 'cellRendererChips',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CURRENCY',
    field: 'currency',
    flex: 1,
    minWidth: 115,
    sortable: true,
    headerComponentParams: {
      reservedList: 'currencies',
    },
    filter: 'listColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.EFFECTIVE_FROM',
    field: 'actualEffectiveFrom',
    flex: 1,
    minWidth: 150,
    valueFormatter: parseDate,
    filter: 'dateColumnFilter',
    sortable: true,
    headerComponentParams: {
      startWithTime: false,
      endWithTime: false,
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.EFFECTIVE_TILL',
    field: 'actualEffectiveTill',
    valueFormatter: value => parseDate(value) || '∞',
    flex: 1,
    minWidth: 132,
    sortable: true,
    filter: 'dateColumnFilter',
    headerComponentParams: {
      startWithTime: false,
      endWithTime: false,
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.AGREEMENTS.CREDIT_LIMIT',
    field: 'creditLimit',
    flex: 1,
    minWidth: 125,
    valueFormatter: ({ data }) => data?.creditLimit || '∞',
    sortable: true,
    filter: 'numberColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.AGREEMENTS.BILLING_TIMEZONE',
    field: 'actualBillingTz',
    flex: 1,
    minWidth: 165,
    sortable: true,
    headerComponentParams: {
      reservedList: 'timezones',
    },
    filter: 'listColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.AGREEMENTS.BILLING_PERIOD',
    field: 'actualBillingPeriod',
    valueFormatter: ({ data }) => parseConstOptions(BILLING_PERIOD, data?.actualBillingPeriod),
    cellRenderer: 'cellRendererText',
    flex: 1,
    minWidth: 140,
    sortable: true,
    headerComponentParams: {
      items: BILLING_PERIOD,
    },
    filter: 'listColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.AGREEMENTS.SERVICES',
    field: 'enabledServiceCount',
    flex: 1,
    minWidth: 143,
    sortable: true,
    cellRenderer: 'cellRendererServices',
    cellRendererParams: {
      instanceKey: 'services',
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.AGREEMENTS.PACKS',
    field: 'enabledPackageCount',
    flex: 1,
    minWidth: 143,
    sortable: true,
    cellRenderer: 'cellRendererServices',
    cellRendererParams: {
      instanceKey: 'packs',
    },
    cellStyle,
  },
];
const ACCOUNTS = [
  {
    headerName: 'INSTANCES.ID',
    field: 'id',
    flex: 1,
    // minWidth: 80,
    sortable: true,
    filter: 'numberColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.COMPANY',
    field: 'partnerName',
    flex: 1,
    sortable: true,
    filter: 'listColumnFilter',
    headerComponentParams: {
      reservedList: 'companies',
      filterKey: 'partnerId',
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.ACCOUNT',
    field: 'name',
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.COMPANY_NAME',
    field: 'legalCompanyName',
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.ACCOUNTS.BALANCE_LIMIT',
    field: 'balanceLimit',
    flex: 1,
    sortable: true,
    filter: 'numberColumnFilter',
    cellRenderer: 'cellRendererNumber',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.ACCOUNTS.IN_CREDIT',
    field: 'inCredit',
    flex: 1,
    sortable: true,
    filter: 'numberColumnFilter',
    cellRenderer: 'cellRendererNumber',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.ACCOUNTS.OUT_CREDIT',
    field: 'outCredit',
    flex: 1,
    sortable: true,
    filter: 'numberColumnFilter',
    cellRenderer: 'cellRendererNumber',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CURRENCY',
    field: 'currencyCode',
    flex: 1,
    headerComponentParams: {
      reservedList: 'currencies',
    },
    filter: 'listColumnFilter',
    sortable: true,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.BLOCKED',
    field: 'isBlocked',
    flex: 1,
    headerComponentParams: {
      items: BLOCKED,
      type: 'boolean-list',
    },
    valueFormatter: ({ value }) => ({
      tag: `STATES.${value ? 'DISABLED' : 'ACTIVE'}`,
      color: value ? 'danger' : 'success',
    }),
    cellRenderer: 'cellRendererChips',
    filter: 'listColumnFilter',
    sortable: true,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CREATED',
    field: 'createdAt',
    flex: 1,
    valueFormatter: parseDate,
    filter: 'dateColumnFilter',
    sortable: true,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.UPDATED',
    field: 'updatedAt',
    flex: 1,
    valueFormatter: parseDate,
    filter: 'dateColumnFilter',
    sortable: true,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.DESCRIPTION',
    field: 'description',
    flex: 1,
    filter: 'maskColumnFilter',
    sortable: true,
    cellStyle,
  },
];
const LEGAL_ENTITIES = [
  {
    headerName: 'INSTANCES.ID',
    field: 'id',
    flex: 1,
    minWidth: 110,
    filter: 'numberColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.NAME',
    field: 'name',
    flex: 1,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.COMPANY',
    field: 'companyId',
    flex: 1,
    sortable: true,
    filter: 'listColumnFilter',
    cellRenderer: 'cellRendererDictionaryMap',
    cellRendererParams: {
      dictionaryKey: 'companiesDictionary',
    },
    headerComponentParams: {
      reservedList: 'companies',
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.LEGAL_ENTITIES.PORTAL',
    field: 'portalId',
    flex: 1,
    sortable: true,
    filter: 'listColumnFilter',
    cellRenderer: 'cellRendererDictionaryMap',
    cellRendererParams: {
      dictionaryKey: 'portalsDictionary',
    },
    headerComponentParams: {
      reservedList: 'portals',
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.LEGAL_ENTITIES.LEGAL_ADDRESS',
    field: 'legalAddress',
    flex: 1,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.LEGAL_ENTITIES.BILLING_ADDRESS',
    field: 'billingAddress',
    flex: 1,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.LEGAL_ENTITIES.LEGAL_COMPANY_NAME',
    field: 'legalCompanyName',
    flex: 1,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.LEGAL_ENTITIES.TAX_ID_NUM',
    field: 'taxIdentificationNo',
    flex: 1,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.LEGAL_ENTITIES.IS_DEFAULT',
    field: 'isDefault',
    flex: 1,
    cellStyle,
  },
];
const SMPP_CONNECTIONS = [
  {
    headerName: 'INSTANCES.ID',
    field: 'id',
    flex: 1,
    sortable: false,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.NAME',
    field: 'name',
    flex: 1,
    sortable: false,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.SMPP.HOST_NAME',
    field: 'hostName',
    flex: 1,
    sortable: false,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.SMPP.PORT',
    field: 'port',
    flex: 1,
    sortable: false,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.SMPP.SYSTEM_ID',
    field: 'systemId',
    flex: 1,
    sortable: false,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.STATUS',
    field: 'enabled',
    flex: 1,
    valueFormatter: ({ value }) => ({
      tag: `STATES.${value ? 'ACTIVE' : 'DISABLED'}`,
      color: value ? 'success' : 'danger',
    }),
    cellRenderer: 'cellRendererChips',
    sortable: false,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.SMPP.MO_INVERSE_ADDRESS_MODE',
    field: 'moInverseAddressMode',
    flex: 1,
    sortable: false,
    cellStyle,
  },
];
const HTTP_CONNECTIONS = [
  {
    headerName: 'INSTANCES.ID',
    field: 'id',
    flex: 1,
    filter: 'numberColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.NAME',
    field: 'name',
    flex: 1,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.HTTP.SIGNATURE_KEY',
    field: 'signatureKey',
    flex: 1,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.STATUS',
    field: 'enabled',
    flex: 1,
    headerComponentParams: {
      items: [
        {
          value: true,
          label: 'STATES.ACTIVE',
        },
        {
          value: false,
          label: 'STATES.DISABLED',
        },
      ],
    },
    filter: 'listColumnFilter',
    valueFormatter: ({ value }) => ({
      tag: `STATES.${value ? 'ACTIVE' : 'DISABLED'}`,
      color: value ? 'success' : 'danger',
    }),
    cellRenderer: 'cellRendererChips',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.VENDOR_TYPE',
    field: 'vendorType',
    headerComponentParams: {
      items: process.env.NODE_ENV !== 'production' || window.location.origin.includes('mkitdev2.alarislabs')
        ? HTTP_VENDOR_TYPES_OPTIONS
        : HTTP_VENDOR_TYPES_OPTIONS.filter(item => item.value !== 'mkit_http_vk_ok'),
    },
    flex: 1,
    minWidth: 70,
    filter: 'listColumnFilter',
    valueFormatter: ({ value }) => HTTP_VENDOR_TYPES_DICTIONARY[value],
    cellStyle,
  },
];
const SERVICES_ENABLED = [
  {
    headerName: 'INSTANCES.ID',
    field: 'id',
    flex: 1,
    sortable: true,
    filter: 'numberColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.COMPANY',
    field: 'agreementCompanyId',
    flex: 1,
    cellStyle,
    sortable: false,
    filter: 'listColumnFilter',
    headerComponentParams: {
      reservedList: 'companies',
    },
    valueFormatter: ({ data }) => data.agreementCompanyName,
  },
  {
    headerName: 'INSTANCES.AGREEMENT',
    field: 'agreementId',
    flex: 1,
    cellStyle,
    sortable: false,
    filter: 'listColumnFilter',
    headerComponentParams: {
      reservedList: 'agreements',
    },
    valueFormatter: ({ data }) => `${data.agreementCode} (${data.agreementCurrency})`,
  },
  {
    headerName: 'INSTANCES.SERVICE',
    field: 'serviceId',
    flex: 1,
    cellStyle,
    sortable: false,
    filter: 'listColumnFilter',
    cellRenderer: 'cellRendererDictionaryMap',
    cellRendererParams: {
      dictionaryKey: 'servicesDictionary',
    },
    headerComponentParams: {
      reservedList: 'services',
    },
  },
  {
    headerName: 'INSTANCES.EFFECTIVE_FROM',
    field: 'effectiveFrom',
    flex: 1,
    valueFormatter: parseDateWithTime,
    filter: 'dateColumnFilter',
    sortable: true,
    headerComponentParams: {
      startWithTime: false,
      endWithTime: false,
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.EFFECTIVE_TILL',
    field: 'effectiveTill',
    valueFormatter: value => parseDateWithTime(value) || '∞',
    flex: 1,
    sortable: true,
    filter: 'dateColumnFilter',
    headerComponentParams: {
      startWithTime: false,
      endWithTime: false,
      withoutEmpty: true,
      withIndefinitely: true,
    },
    cellStyle,
  },
];
const PACKS_ENABLED = [
  {
    headerName: 'INSTANCES.ID',
    field: 'id',
    flex: 1,
    sortable: true,
    filter: 'numberColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.AGREEMENT',
    field: 'agreementId',
    flex: 1,
    cellStyle,
    sortable: false,
    filter: 'listColumnFilter',
    headerComponentParams: {
      reservedList: 'agreements',
    },
    valueFormatter: ({ data }) => `${data.agreementCode} (${data.agreementCurrency})`,
  },
  {
    headerName: 'INSTANCES.PACKS_ENABLED.SERVICE',
    field: 'serviceId',
    flex: 1,
    cellStyle,
    sortable: false,
    filter: 'listColumnFilter',
    cellRenderer: 'cellRendererDictionaryMap',
    cellRendererParams: {
      dictionaryKey: 'servicesDictionary',
    },
    headerComponentParams: {
      reservedList: 'services',
    },
  },
  {
    headerName: 'INSTANCES.COMPANY',
    field: 'companyId',
    flex: 1,
    cellStyle,
    filter: 'listColumnFilter',
    headerComponentParams: {
      reservedList: 'companies',
    },
    cellRenderer: 'cellRendererDictionaryMap',
    cellRendererParams: {
      dictionaryKey: 'companiesDictionary',
    },
  },
  {
    headerName: 'INSTANCES.PACKS_ENABLED.COST',
    field: 'activationCost',
    flex: 1,
    sortable: true,
    filter: 'numberColumnFilter',
    cellRenderer: 'cellRendererNumber',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CURRENCY',
    field: 'activationCostCurrency',
    flex: 1,
    sortable: true,
    headerComponentParams: {
      reservedList: 'currencies',
    },
    filter: 'listColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.EFFECTIVE_FROM',
    field: 'effectiveFrom',
    flex: 1,
    valueFormatter: parseDateWithTime,
    filter: 'dateColumnFilter',
    sortable: true,
    headerComponentParams: {
      startWithTime: false,
      endWithTime: false,
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.EFFECTIVE_TILL',
    field: 'effectiveTill',
    valueFormatter: value => parseDateWithTime(value) || '∞',
    flex: 1,
    sortable: true,
    filter: 'dateColumnFilter',
    headerComponentParams: {
      startWithTime: false,
      endWithTime: false,
    },
    cellStyle,
  },
];
const PAYMENTS = [
  {
    headerName: 'INSTANCES.ID',
    field: 'id',
    flex: 1,
    minWidth: 70,
    filter: 'numberColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.COMPANY',
    field: 'companyName',
    flex: 1,
    minWidth: 120,
    sortable: true,
    filter: 'listColumnFilter',
    headerComponentParams: {
      reservedList: 'companies',
      filterKey: 'companyId',
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.AGREEMENT',
    field: 'agreementId',
    flex: 1,
    minWidth: 130,
    cellStyle,
    sortable: false,
    filter: 'listColumnFilter',
    headerComponentParams: {
      reservedList: 'agreements',
    },
    valueFormatter: ({ data }) => `${data.agreementCode} (${data.agreementCurrency})`,
  },
  {
    headerName: 'INSTANCES.PAYMENTS.REFERENCE_CODE',
    field: 'referenceCode',
    flex: 1,
    minWidth: 160,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  // {
  //   headerName: 'COLUMNS.PAYMENTS.CLIENT',
  //   field: 'balanceLimit',
  //   flex: 1,
  // },
  {
    headerName: 'INSTANCES.PAYMENTS.AMOUNT',
    field: 'paymentCurrencyAmount',
    flex: 1,
    filter: 'numberColumnFilter',
    cellRenderer: 'cellRendererNumber',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CURRENCY',
    field: 'paymentCurrencyCode',
    headerComponentParams: {
      reservedList: 'currencies',
    },
    filter: 'listColumnFilter',
    flex: 1,
    minWidth: 115,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CREATED',
    field: 'createdAt',
    valueFormatter: parseDateWithTime,
    flex: 1,
    filter: 'dateColumnFilter',
    minWidth: 150,
    cellStyle,
    // cellRenderer: 'cellRendererDate',
  },
  {
    headerName: 'INSTANCES.UPDATED',
    field: 'updatedAt',
    valueFormatter: parseDate,
    flex: 1,
    minWidth: 110,
    filter: 'dateColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.DESCRIPTION',
    field: 'description',
    filter: 'maskColumnFilter',
    flex: 1,
    minWidth: 263,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.DIRECTION',
    field: 'direction',
    filter: 'listColumnFilter',
    cellRenderer: 'cellRendererDirection',
    headerComponentParams: {
      items: DIRECTIONS,
    },
    valueFormatter: ({ value }) => DIRECTIONS[value].label,
    flex: 1,
    minWidth: 125,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.PAYMENTS.PAYMENT_DATE',
    field: 'date',
    flex: 1,
    valueFormatter: parseDate,
    filter: 'dateColumnFilter',
    minWidth: 150,
    cellStyle,
  },
  // {
  //   headerName: 'COLUMNS.PAYMENTS.EXCHANGE_RATE',
  //   field: 'balanceLimit',
  //   flex: 1,
  // },
  // {
  //   headerName: 'COLUMNS.PAYMENTS.EXCHANGE_RATE_DATE',
  //   field: 'balanceLimit',
  //   flex: 1,
  //   valueFormatter: parseDate,
  // },
  {
    headerName: 'INSTANCES.PAYMENTS.AMOUNT_IN_AGREEMENT_CURRENCY',
    field: 'accountCurrencyAmount',
    filter: 'numberColumnFilter',
    cellRenderer: 'cellRendererNumber',
    flex: 1,
    minWidth: 262,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.PAYMENTS.AGREEMENT_CURRENCY',
    field: 'agreementCurrency',
    headerComponentParams: {
      reservedList: 'currencies',
    },
    filter: 'listColumnFilter',
    flex: 1,
    minWidth: 195,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.PAYMENTS.CONFIRMED',
    field: 'isConfirmed',
    sortable: true,
    filter: 'listColumnFilter',
    cellRenderer: 'cellRendererStatus',
    headerComponentParams: {
      items: CONFIRMED,
    },
    flex: 1,
    minWidth: 136,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.PAYMENTS.EXPIRE_DATE_OF_CONFIRMATION',
    field: 'expiryDate',
    filter: 'dateColumnFilter',
    valueFormatter: parseDate,
    flex: 1,
    minWidth: 240,
    cellStyle,
  },
];
const SENDER_IDS = userCompanyId => [
  {
    headerName: 'INSTANCES.ID',
    field: 'id',
    flex: 1,
    sortable: true,
    filter: 'numberColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.COMPANY',
    field: 'companyId',
    flex: 1,
    minWidth: 180,
    sortable: true,
    filter: 'listColumnFilter',
    headerComponentParams: {
      reservedList: 'companiesWithOwn',
    },
    valueFormatter: ({ data }) => data?.companyName,
    cellRenderer: 'cellRendererCompanyWithChip',
    cellStyle: params => ({
      ...cellStyle(params),
      fontWeight: params.data.companyId === userCompanyId ? 'bold' : '',
    }),
  },
  {
    headerName: 'INSTANCES.CHANNEL',
    field: 'channel',
    flex: 1,
    sortable: true,
    minWidth: 70,
    headerComponentParams: {
      items: process.env.NODE_ENV !== 'production' || window.location.origin.includes('mkitdev2.alarislabs')
        ? CHANNELS_OPTIONS
        : CHANNELS_OPTIONS.filter(item => item.value !== 5),
    },
    valueFormatter: ({ value }) => CHANNELS_DICTIONARY[value],
    cellRenderer: 'cellRendererText',
    filter: 'listColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.SENDER_ID',
    field: 'senderId',
    flex: 1,
    sortable: true,
    minWidth: 70,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.NAME',
    field: 'displayName',
    flex: 1,
    sortable: true,
    minWidth: 70,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.STATUS',
    field: 'status',
    flex: 1,
    sortable: true,
    minWidth: 70,
    headerComponentParams: {
      items: SENDER_STATUSES,
    },
    filter: 'listColumnFilter',
    valueFormatter: ({ value }) => {
      switch (value) {
        case -1: return ({
          tag: 'STATES.SENDER_IDS.BLOCKED',
          color: 'danger',
        });
        case 0: return ({
          tag: 'STATES.DRAFT',
          color: 'primary',
        });
        case 1: return ({
          tag: 'STATES.SENDER_IDS.TESTING',
          color: 'warning',
        });
        case 2: return ({
          tag: 'STATES.SENDER_IDS.ACTIVE',
          color: 'success',
        });
        default: return {};
      }
    },
    cellRenderer: 'cellRendererChips',
    cellStyle,
  },
  // {
  //   headerName: 'INSTANCES.COUNTRIES',
  //   field: 'countryList',
  //   flex: 1,
  //   sortable: true,
  //   minWidth: 70,
  //   filter: 'maskColumnFilter',
  //   cellStyle,
  // },
  {
    headerName: 'INSTANCES.SENDER_IDS.WEBHOOK_GUID',
    field: 'webHookGuid',
    flex: 1,
    sortable: true,
    minWidth: 70,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.AGREEMENT',
    field: 'agreementCode',
    flex: 1,
    sortable: false,
    minWidth: 70,
    // filter: 'maskColumnFilter',
    valueFormatter: ({ value, data }) => (value ? `${value} (${data.agreementCurrency})` : ''),
    cellStyle,
  },
  {
    headerName: 'INSTANCES.VENDOR_TYPE',
    field: 'vendorType',
    flex: 1,
    sortable: true,
    minWidth: 70,
    headerComponentParams: {
      items: [
        ...SMS_VENDOR_TYPES,
        ...RCS_VENDOR_TYPES,
        ...WHATSAPP_VENDOR_TYPES,
        ...VIBER_VENDOR_TYPES_OPTIONS,
        ...(process.env.NODE_ENV !== 'production' || window.location.origin.includes('mkitdev2.alarislabs')
          ? VK_OK_VENDOR_TYPES_OPTIONS
          : []),
      ],
    },
    filter: 'listColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CREATED',
    field: 'createdAt',
    flex: 1,
    valueFormatter: parseDate,
    filter: 'dateColumnFilter',
    sortable: true,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.UPDATED',
    field: 'updatedAt',
    flex: 1,
    valueFormatter: parseDate,
    filter: 'dateColumnFilter',
    sortable: true,
    cellStyle,
  },
];
const APPLICATIONS = [
  {
    headerName: 'INSTANCES.APPLICATIONS.SUBMITTED',
    field: 'createdAt',
    flex: 1,
    valueFormatter: parseDate,
    filter: 'dateColumnFilter',
    sortable: true,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.COMPANY',
    field: 'companyId',
    flex: 1,
    sortable: true,
    filter: 'listColumnFilter',
    headerComponentParams: {
      reservedList: 'companies',
    },
    valueFormatter: ({ data }) => data?.companyName,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.TYPE',
    field: 'applicationType',
    flex: 1,
    sortable: true,
    headerComponentParams: {
      items: APPLICATION_TYPES,
    },
    filter: 'listColumnFilter',
    cellRenderer: 'cellRendererText',
    valueFormatter: ({ value }) => (value === 2 ? 'STATES.APPLICATIONS.CHANGE' : 'STATES.APPLICATIONS.CREATE'),
    cellStyle: params => ({
      ...cellStyle(params),
      color: params.data.applicationType === 2 ? 'var(--blue50)' : 'var(--green50)',
    }),
  },
  {
    headerName: 'INSTANCES.CHANNEL',
    field: 'channel',
    flex: 1,
    filter: 'listColumnFilter',
    headerComponentParams: {
      items: process.env.NODE_ENV !== 'production' || window.location.origin.includes('mkitdev2.alarislabs')
        ? CHANNELS_OPTIONS
        : CHANNELS_OPTIONS.filter(item => item.value !== 5),
    },
    valueFormatter: ({ value }) => CHANNELS_DICTIONARY[value],
    cellStyle,
  },
  {
    headerName: 'INSTANCES.SENDER_ID',
    field: 'senderDisplayName',
    flex: 1,
    sortable: true,
    minWidth: 70,
    filter: 'listColumnFilter',
    headerComponentParams: {
      reservedList: 'senders',
      filterKey: 'senderIdId',
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.STATUS',
    field: 'status',
    flex: 1,
    sortable: true,
    minWidth: 70,
    headerComponentParams: {
      items: APPLICATION_STATUSES_OPTIONS,
    },
    filter: 'listColumnFilter',
    valueFormatter: ({ value }) => ({ ...APPLICATION_STATUSES[value.toString()] }),
    cellRenderer: 'cellRendererChips',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.APPLICATIONS.ASSIGNED_TO',
    field: 'managerUser',
    flex: 1,
    minWidth: 160,
    valueFormatter: ({ value }) => (value ? value.username : ''),
    cellStyle,
  },
  {
    headerName: 'INSTANCES.APPLICATIONS.REJECT_REASON',
    field: 'rejectionReason',
    flex: 1,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.COMMENT',
    field: 'comment',
    flex: 1,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.UPDATED',
    field: 'updatedAt',
    flex: 1,
    valueFormatter: parseDate,
    filter: 'dateColumnFilter',
    sortable: true,
    cellStyle,
  },
];
const CHARGES = onTotalDueClick => ([
  {
    headerName: 'INSTANCES.CHARGES.SOURCE',
    field: 'source',
    flex: 1,
    cellStyle,
    // filter: 'listColumnFilter',
    sortable: false,
    valueFormatter: ({ value }) => ({
      tag: CHARGE_SOURCES[value]?.label,
      color: CHARGE_SOURCES[value]?.color,
      icon: CHARGE_SOURCES[value]?.icon,
    }),
    headerComponentParams: {
      items: [
        {
          value: 0,
          label: CHARGE_SOURCES[0].label,
        },
        {
          value: 1,
          label: CHARGE_SOURCES[1].label,
        },
      ],
    },
    cellRenderer: 'cellRendererChips',
  },
  {
    headerName: 'INSTANCES.ID',
    field: 'id',
    flex: 1,
    cellStyle,
    // filter: 'numberColumnFilter',
    sortable: false,
  },
  {
    headerName: 'INSTANCES.DIRECTION',
    field: 'direction',
    flex: 1,
    minWidth: 140,
    // filter: 'listColumnFilter',
    sortable: false,
    valueFormatter: ({ value }) => ({
      tag: INVOICE_DIRECTIONS[value]?.label,
      color: INVOICE_DIRECTIONS[value]?.color,
      icon: INVOICE_DIRECTIONS[value]?.icon,
    }),
    headerComponentParams: {
      items: [
        {
          value: 0,
          label: INVOICE_DIRECTIONS[0].label,
        },
        {
          value: 1,
          label: INVOICE_DIRECTIONS[1].label,
        },
      ],
    },
    cellRenderer: 'cellRendererChips',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.START_PERIOD',
    field: 'periodStart',
    flex: 1,
    minWidth: 150,
    // filter: 'dateColumnFilter',
    sortable: false,
    cellStyle,
    valueFormatter: parseDate,
  },
  {
    headerName: 'INSTANCES.END_PERIOD',
    field: 'periodEnd',
    minWidth: 150,
    flex: 1,
    // filter: 'dateColumnFilter',
    sortable: false,
    cellStyle,
    valueFormatter: parseDate,
  },
  {
    headerName: 'INSTANCES.SERVICE',
    field: 'serviceId',
    flex: 1,
    cellStyle,
    // filter: 'listColumnFilter',
    sortable: false,
    cellRenderer: 'cellRendererDictionaryMap',
    cellRendererParams: {
      dictionaryKey: 'servicesDictionary',
    },
    headerComponentParams: {
      reservedList: 'services',
    },
  },
  {
    headerName: 'INSTANCES.RESOURCE',
    field: 'resourceId',
    flex: 1,
    minWidth: 110,
    sortable: true,
    filter: 'listColumnFilter',
    cellRenderer: 'cellRendererDictionaryMap',
    cellRendererParams: {
      dictionaryKey: 'resourcesDictionary',
    },
    headerComponentParams: {
      reservedList: 'resources',
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CHARGES.COUNT',
    field: 'billableCount',
    minWidth: 120,
    flex: 1,
    cellRenderer: 'cellRendererNumber',
    cellRendererParams: {
      cutDecimalZeroes: true,
    },
    // filter: 'numberColumnFilter',
    sortable: false,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CHARGES.VOLUME',
    field: 'billableVolume',
    minWidth: 120,
    flex: 1,
    cellRenderer: 'cellRendererNumber',
    cellRendererParams: {
      cutDecimalZeroes: true,
    },
    // filter: 'numberColumnFilter',
    sortable: false,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.SYSTEM_NET',
    field: 'billableNetAmount',
    minWidth: 175,
    flex: 1,
    cellRenderer: 'cellRendererNumber',
    // filter: 'numberColumnFilter',
    sortable: false,
    cellStyle: params => ({
      ...cellStyle(params),
      color: `var(--${params.value < 0 ? 'red60' : 'grey100'})`,
    }),
  },
  {
    headerName: 'INSTANCES.SYSTEM_TAX',
    field: 'billableTaxAmount',
    minWidth: 175,
    flex: 1,
    cellRenderer: 'cellRendererNumber',
    // filter: 'numberColumnFilter',
    sortable: false,
    cellStyle: params => ({
      ...cellStyle(params),
      color: `var(--${params.value < 0 ? 'red60' : 'grey100'})`,
    }),
  },
  {
    headerName: 'INSTANCES.SYSTEM_TOTAL',
    field: 'billableTotalAmount',
    minWidth: 180,
    flex: 1,
    cellRenderer: 'cellRendererNumber',
    // filter: 'numberColumnFilter',
    sortable: false,
    cellStyle: params => ({
      ...cellStyle(params),
      color: `var(--${params.value < 0 ? 'red60' : 'grey100'})`,
    }),
  },
  {
    headerName: 'INSTANCES.TOTAL_DUE',
    field: 'dueAmount',
    minWidth: 120,
    flex: 1,
    cellRenderer: 'cellRendererNumber',
    // filter: 'numberColumnFilter',
    sortable: false,
    cellRendererParams: {
      onClick: onTotalDueClick,
    },
    cellStyle: params => ({
      ...cellStyle(params),
      color: 'var(--blue70)',
      textDecoration: 'underline',
    }),
  },
  {
    headerName: 'INSTANCES.CURRENCY',
    field: 'currency',
    minWidth: 120,
    flex: 1,
    // filter: 'listColumnFilter',
    sortable: false,
    headerComponentParams: {
      reservedList: 'currencies',
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CHARGES.VERSION',
    field: 'version',
    minWidth: 120,
    flex: 1,
    // filter: 'numberColumnFilter',
    sortable: false,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CHARGES.CORRECTION_FOR',
    field: 'correctionFor',
    minWidth: 150,
    flex: 1,
    // filter: 'numberColumnFilter',
    sortable: false,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.DISPUTE_STATUS',
    field: 'disputeStatus',
    minWidth: 180,
    flex: 1,
    // filter: 'listColumnFilter',
    sortable: false,
    headerComponentParams: {
      items: [
        {
          value: 0,
          label: 'STATES.INVOICES.NOT_DISPUTED',
        },
        {
          value: 1,
          label: 'STATES.INVOICES.DISPUTED',
        },
        {
          value: 2,
          label: 'STATES.INVOICES.DISPUTE_SETTLED',
        },
      ],
    },
    valueFormatter: ({ value }) => ({
      tag: INVOICE_DISPUTE_STATUSES[value]?.label,
      color: INVOICE_DISPUTE_STATUSES[value]?.color,
      icon: INVOICE_DISPUTE_STATUSES[value]?.icon,
    }),
    cellRenderer: 'cellRendererChips',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CHARGES.DUE_SOURCE',
    field: 'dueSource',
    minWidth: 160,
    flex: 1,
    // filter: 'listColumnFilter',
    sortable: false,
    valueFormatter: ({ value }) => CHARGE_DUE_SOURCES[value],
    cellRenderer: 'cellRendererText',
    headerComponentParams: {
      items: [
        {
          value: 0,
          label: 'STATES.CHARGES.SYSTEM_ESTIMATE',
        },
        {
          value: 1,
          label: 'STATES.CHARGES.COUNTERPARTY_ESTIMATE',
        },
      ],
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CHARGES.COUNTERPARTY_EST',
    field: 'counterpartyAmount',
    minWidth: 180,
    flex: 1,
    cellRenderer: 'cellRendererNumber',
    // filter: 'numberColumnFilter',
    sortable: false,
    cellStyle: params => ({
      ...cellStyle(params),
      color: `var(--${params.value < 0 ? 'red60' : 'grey100'})`,
    }),
  },
  {
    headerName: 'INSTANCES.CREATED',
    field: 'createdAt',
    flex: 1,
    minWidth: 150,
    // filter: 'dateColumnFilter',
    sortable: false,
    cellStyle,
    valueFormatter: parseDate,
  },
  {
    headerName: 'INSTANCES.UPDATED',
    field: 'updatedAt',
    flex: 1,
    minWidth: 150,
    // filter: 'dateColumnFilter',
    sortable: false,
    cellStyle,
    valueFormatter: parseDate,
  },
  {
    headerName: 'INSTANCES.ON_HOLD_NET',
    field: 'onHoldNetAmount',
    minWidth: 150,
    flex: 1,
    cellRenderer: 'cellRendererNumber',
    // filter: 'numberColumnFilter',
    sortable: false,
    cellStyle: params => ({
      ...cellStyle(params),
      color: `var(--${params.value < 0 ? 'red60' : 'grey100'})`,
    }),
  },
  {
    headerName: 'INSTANCES.ON_HOLD_TAX',
    field: 'onHoldTaxAmount',
    minWidth: 150,
    flex: 1,
    cellRenderer: 'cellRendererNumber',
    // filter: 'numberColumnFilter',
    sortable: false,
    cellStyle: params => ({
      ...cellStyle(params),
      color: `var(--${params.value < 0 ? 'red60' : 'grey100'})`,
    }),
  },
  {
    headerName: 'INSTANCES.ON_HOLD_TOTAL',
    field: 'onHoldTotalAmount',
    minWidth: 150,
    flex: 1,
    cellRenderer: 'cellRendererNumber',
    // filter: 'numberColumnFilter',
    sortable: false,
    cellStyle: params => ({
      ...cellStyle(params),
      color: `var(--${params.value < 0 ? 'red60' : 'grey100'})`,
    }),
  },
  {
    headerName: 'INSTANCES.PERIOD_START_TZ',
    field: 'periodStartTz',
    flex: 1,
    // filter: 'maskColumnFilter',
    sortable: false,
    minWidth: 205,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.PERIOD_START_ABSOLUTE',
    field: 'periodStartAbs',
    flex: 1,
    minWidth: 205,
    // filter: 'dateColumnFilter',
    sortable: false,
    cellStyle,
    valueFormatter: parseDate,
  },
  {
    headerName: 'INSTANCES.PERIOD_END_TZ',
    field: 'periodEndTz',
    flex: 1,
    minWidth: 200,
    // filter: 'maskColumnFilter',
    sortable: false,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.PERIOD_END_ABSOLUTE',
    field: 'periodEndAbs',
    flex: 1,
    minWidth: 200,
    // filter: 'dateColumnFilter',
    sortable: false,
    cellStyle,
    valueFormatter: parseDate,
  },
]);
const CHARGE_DETAILS = [
  {
    headerName: 'INSTANCES.CHARGES.SOURCE',
    field: 'source',
    flex: 1,
    cellStyle,
    // filter: 'listColumnFilter',
    sortable: false,
    valueFormatter: ({ value }) => ({
      tag: CHARGE_SOURCES[value]?.label,
      color: CHARGE_SOURCES[value]?.color,
      icon: CHARGE_SOURCES[value]?.icon,
    }),
    headerComponentParams: {
      items: [
        {
          value: 0,
          label: CHARGE_SOURCES[0].label,
        },
        {
          value: 1,
          label: CHARGE_SOURCES[1].label,
        },
      ],
    },
    cellRenderer: 'cellRendererChips',
  },
  {
    headerName: 'INSTANCES.ID',
    field: 'id',
    flex: 1,
    cellStyle,
    // filter: 'numberColumnFilter',
    sortable: false,
  },
  {
    headerName: 'INSTANCES.CHARGES.RATE_ID',
    field: 'rateId',
    flex: 1,
    // filter: 'numberColumnFilter',
    sortable: false,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CHARGES.PRICE_TYPE',
    field: 'priceType',
    flex: 1,
    // filter: 'numberColumnFilter',
    sortable: false,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CHARGES.SCALE_TYPE',
    field: 'scaleType',
    flex: 1,
    // filter: 'numberColumnFilter',
    sortable: false,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CHARGES.SCALE_TIER',
    field: 'scaleTier',
    flex: 1,
    // filter: 'numberColumnFilter',
    sortable: false,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CHARGES.SCALE_GROUP',
    field: 'mock',
    flex: 1,
    // filter: 'numberColumnFilter',
    sortable: false,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CHARGES.PRICE',
    field: 'price',
    flex: 1,
    // filter: 'numberColumnFilter',
    sortable: false,
    cellRenderer: 'cellRendererNumber',
    cellRendererParams: {
      decimalsCount: 6,
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CHARGES.COUNT_BIL',
    field: 'billableCount',
    flex: 1,
    minWidth: 170,
    // filter: 'numberColumnFilter',
    sortable: false,
    cellRenderer: 'cellRendererNumber',
    cellRendererParams: {
      cutDecimalZeroes: true,
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CHARGES.VOLUME_BIL',
    field: 'billableVolume',
    flex: 1,
    minWidth: 170,
    // filter: 'numberColumnFilter',
    sortable: false,
    cellRenderer: 'cellRendererNumber',
    cellRendererParams: {
      cutDecimalZeroes: true,
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CHARGES.NET_BIL',
    field: 'billableNetAmount',
    flex: 1,
    minWidth: 150,
    // filter: 'numberColumnFilter',
    sortable: false,
    cellRenderer: 'cellRendererNumber',
    cellRendererParams: {
      decimalsCount: 6,
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CHARGES.TAX_BIL',
    field: 'billableTaxAmount',
    flex: 1,
    minWidth: 150,
    // filter: 'numberColumnFilter',
    sortable: false,
    cellRenderer: 'cellRendererNumber',
    cellRendererParams: {
      decimalsCount: 6,
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CHARGES.TOTAL_BIL',
    field: 'billableTotalAmount',
    flex: 1,
    minWidth: 150,
    // filter: 'numberColumnFilter',
    sortable: false,
    cellRenderer: 'cellRendererNumber',
    cellRendererParams: {
      decimalsCount: 6,
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CREATED',
    field: 'createdAt',
    flex: 1,
    minWidth: 150,
    // filter: 'dateColumnFilter',
    sortable: false,
    cellStyle,
    valueFormatter: parseDate,
  },
  {
    headerName: 'INSTANCES.UPDATED',
    field: 'updatedAt',
    flex: 1,
    minWidth: 150,
    // filter: 'dateColumnFilter',
    sortable: false,
    cellStyle,
    valueFormatter: parseDate,
  },
  {
    headerName: 'INSTANCES.CHARGES.COUNT_HOLD',
    field: 'onHoldCount',
    flex: 1,
    minWidth: 160,
    // filter: 'numberColumnFilter',
    sortable: false,
    cellRenderer: 'cellRendererNumber',
    cellRendererParams: {
      cutDecimalZeroes: true,
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CHARGES.VOLUME_HOLD',
    field: 'onHoldVolume',
    flex: 1,
    minWidth: 170,
    // filter: 'numberColumnFilter',
    sortable: false,
    cellRenderer: 'cellRendererNumber',
    cellRendererParams: {
      cutDecimalZeroes: true,
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CHARGES.NET_HOLD',
    field: 'onHoldNetAmount',
    flex: 1,
    minWidth: 150,
    // filter: 'numberColumnFilter',
    sortable: false,
    cellRenderer: 'cellRendererNumber',
    cellRendererParams: {
      decimalsCount: 6,
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CHARGES.TAX_HOLD',
    field: 'onHoldTaxAmount',
    flex: 1,
    minWidth: 150,
    // filter: 'numberColumnFilter',
    sortable: false,
    cellRenderer: 'cellRendererNumber',
    cellRendererParams: {
      decimalsCount: 6,
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CHARGES.TOTAL_HOLD',
    field: 'onHoldTotalAmount',
    flex: 1,
    minWidth: 150,
    // filter: 'numberColumnFilter',
    sortable: false,
    cellRenderer: 'cellRendererNumber',
    cellRendererParams: {
      decimalsCount: 6,
    },
    cellStyle,
  },
];
const INVOICES = onTotalDueClick => ([
  {
    headerName: 'INSTANCES.ID',
    field: 'id',
    flex: 1,
    cellStyle,
    filter: 'numberColumnFilter',
  },
  {
    headerName: 'INSTANCES.COMPANY',
    field: 'companyId',
    flex: 1,
    minWidth: 120,
    sortable: true,
    filter: 'listColumnFilter',
    cellRenderer: 'cellRendererDictionaryMap',
    cellRendererParams: {
      dictionaryKey: 'companiesDictionary',
    },
    headerComponentParams: {
      reservedList: 'companies',
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.AGREEMENT',
    field: 'agreementId',
    flex: 1,
    minWidth: 130,
    filter: 'listColumnFilter',
    headerComponentParams: {
      reservedList: 'agreements',
    },
    valueFormatter: ({ data }) => data?.agreementCode,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.OWN_LEGAL_ENTITY',
    field: 'parentCompanyLegalEntityName',
    flex: 1,
    minWidth: 165,
    sortable: true,
    filter: 'listColumnFilter',
    headerComponentParams: {
      reservedList: 'ownLegalEntities',
      filterKey: 'parentCompanyLegalEntityId',
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.DIRECTION',
    field: 'direction',
    flex: 1,
    minWidth: 140,
    filter: 'listColumnFilter',
    valueFormatter: ({ value }) => ({
      tag: INVOICE_DIRECTIONS[value]?.label,
      color: INVOICE_DIRECTIONS[value]?.color,
      icon: INVOICE_DIRECTIONS[value]?.icon,
    }),
    headerComponentParams: {
      items: [
        {
          value: 0,
          label: INVOICE_DIRECTIONS[0].label,
        },
        {
          value: 1,
          label: INVOICE_DIRECTIONS[1].label,
        },
      ],
    },
    cellRenderer: 'cellRendererChips',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.TYPE',
    field: 'documentType',
    flex: 1,
    filter: 'listColumnFilter',
    cellRenderer: 'cellRendererText',
    valueFormatter: ({ value }) => {
      switch (value) {
        case 0:
          return 'STATES.INVOICES.INVOICE';
        case 1:
          return 'STATES.INVOICES.CREDIT_NOTE';
        default:
          return null;
      }
    },
    headerComponentParams: {
      items: [
        {
          value: 0,
          label: 'STATES.INVOICES.INVOICE',
        },
        {
          value: 1,
          label: 'STATES.INVOICES.CREDIT_NOTE',
        },
      ],
    },
    cellStyle: params => ({
      ...cellStyle(params),
      color: `var(--${params.value === 1 ? 'red60' : 'grey100'})`,
    }),
  },
  {
    headerName: 'INSTANCES.INVOICES.PERIOD',
    field: 'periodDescription',
    flex: 1,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.START_PERIOD',
    field: 'periodStart',
    flex: 1,
    minWidth: 150,
    filter: 'dateColumnFilter',
    cellStyle,
    valueFormatter: parseDate,
  },
  {
    headerName: 'INSTANCES.END_PERIOD',
    field: 'periodEnd',
    minWidth: 150,
    flex: 1,
    filter: 'dateColumnFilter',
    cellStyle,
    valueFormatter: parseDate,
  },
  {
    headerName: 'INSTANCES.INVOICES.REFERENCE',
    field: 'referenceCode',
    minWidth: 135,
    flex: 1,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.TOTAL_DUE',
    field: 'dueAmount',
    minWidth: 120,
    flex: 1,
    cellRenderer: 'cellRendererNumber',
    filter: 'numberColumnFilter',
    cellRendererParams: {
      onClick: onTotalDueClick,
    },
    cellStyle: params => ({
      ...cellStyle(params),
      color: params.node.rowPinned ? 'var(--grey100)' : 'var(--blue70)',
      fontWeight: params.node.rowPinned ? '800' : '600',
      textDecoration: params.node.rowPinned ? 'none' : 'underline',
    }),
  },
  {
    headerName: 'INSTANCES.CURRENCY',
    field: 'currency',
    minWidth: 120,
    flex: 1,
    filter: 'listColumnFilter',
    headerComponentParams: {
      reservedList: 'currencies',
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.STATUS',
    field: 'status',
    minWidth: 130,
    flex: 1,
    filter: 'listColumnFilter',
    headerComponentParams: {
      items: [
        {
          value: -1,
          label: 'STATES.INVOICES.PREVIEW',
        },
        {
          value: 0,
          label: 'STATES.DRAFT',
        },
        {
          value: 1,
          label: 'STATES.CONFIRMED',
        },
      ],
    },
    valueFormatter: ({ value }) => ({
      tag: INVOICE_STATUSES[value]?.label,
      color: INVOICE_STATUSES[value]?.color,
      icon: INVOICE_STATUSES[value]?.icon,
    }),
    cellRenderer: 'cellRendererChips',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.INVOICES.ISSUE_DATE',
    field: 'issueDate',
    flex: 1,
    minWidth: 150,
    filter: 'dateColumnFilter',
    cellStyle,
    valueFormatter: parseDate,
  },
  {
    headerName: 'INSTANCES.INVOICES.DISPATCH_STATUS',
    field: 'dispatchStatus',
    minWidth: 165,
    flex: 1,
    filter: 'listColumnFilter',
    headerComponentParams: {
      items: [
        {
          value: -1,
          label: 'STATES.INVOICES.FAILED',
        },
        {
          value: 0,
          label: 'STATES.INVOICES.NOT_SENT',
        },
        {
          value: 1,
          label: 'STATES.INVOICES.SENT',
        },
        {
          value: 2,
          label: 'STATES.INVOICES.RECEIVED',
        },
      ],
    },
    valueFormatter: ({ value }) => ({
      tag: INVOICE_DISPATCH_STATUSES[value]?.label,
      color: INVOICE_DISPATCH_STATUSES[value]?.color,
      icon: INVOICE_DISPATCH_STATUSES[value]?.icon,
    }),
    cellRenderer: 'cellRendererChips',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.SYSTEM_NET',
    field: 'netAmount',
    minWidth: 175,
    flex: 1,
    cellRenderer: 'cellRendererNumber',
    filter: 'numberColumnFilter',
    cellStyle: params => ({
      ...cellStyle(params),
      color: `var(--${params.value < 0 ? 'red60' : 'grey100'})`,
    }),
  },
  {
    headerName: 'INSTANCES.SYSTEM_TAX',
    field: 'taxAmount',
    minWidth: 175,
    flex: 1,
    cellRenderer: 'cellRendererNumber',
    filter: 'numberColumnFilter',
    cellStyle: params => ({
      ...cellStyle(params),
      color: `var(--${params.value < 0 ? 'red60' : 'grey100'})`,
    }),
  },
  {
    headerName: 'INSTANCES.SYSTEM_TOTAL',
    field: 'totalAmount',
    minWidth: 180,
    flex: 1,
    cellRenderer: 'cellRendererNumber',
    filter: 'numberColumnFilter',
    cellStyle: params => ({
      ...cellStyle(params),
      color: `var(--${params.value < 0 ? 'red60' : 'grey100'})`,
    }),
  },
  {
    headerName: 'INSTANCES.DISPUTE_STATUS',
    field: 'disputeStatus',
    minWidth: 180,
    flex: 1,
    filter: 'listColumnFilter',
    headerComponentParams: {
      items: [
        {
          value: 0,
          label: 'STATES.INVOICES.NOT_DISPUTED',
        },
        {
          value: 1,
          label: 'STATES.INVOICES.DISPUTED',
        },
        {
          value: 2,
          label: 'STATES.INVOICES.DISPUTE_SETTLED',
        },
      ],
    },
    valueFormatter: ({ value }) => ({
      tag: INVOICE_DISPUTE_STATUSES[value]?.label,
      color: INVOICE_DISPUTE_STATUSES[value]?.color,
      icon: INVOICE_DISPUTE_STATUSES[value]?.icon,
    }),
    cellRenderer: 'cellRendererChips',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.DISPUTED',
    field: 'disputedAmount',
    minWidth: 140,
    flex: 1,
    cellRenderer: 'cellRendererNumber',
    filter: 'numberColumnFilter',
    cellStyle: params => ({
      ...cellStyle(params),
      color: `var(--${params.value < 0 ? 'red60' : 'grey100'})`,
    }),
  },
  {
    headerName: 'INSTANCES.INVOICES.DUE_DATE',
    field: 'dueDate',
    flex: 1,
    minWidth: 150,
    filter: 'dateColumnFilter',
    cellStyle,
    valueFormatter: parseDate,
  },
  {
    headerName: 'INSTANCES.INVOICES.PAYMENT_STATUS',
    field: 'paymentStatus',
    minWidth: 180,
    flex: 1,
    filter: 'listColumnFilter',
    headerComponentParams: {
      items: [
        {
          value: -1,
          label: 'STATES.INVOICES.OVERDUE',
        },
        {
          value: 0,
          label: 'STATES.INVOICES.EXPECTED',
        },
        {
          value: 1,
          label: 'STATES.INVOICES.PARTIALLY_COVERED',
        },
        {
          value: 2,
          label: 'STATES.INVOICES.FULLY_COVERED',
        },
      ],
    },
    valueFormatter: ({ value }) => ({
      tag: INVOICE_PAYMENT_STATUSES[value]?.label,
      color: INVOICE_PAYMENT_STATUSES[value]?.color,
      icon: INVOICE_PAYMENT_STATUSES[value]?.icon,
    }),
    cellRenderer: 'cellRendererChips',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.INVOICES.DAYS_OVERDUE',
    field: 'daysOverdue',
    minWidth: 150,
    flex: 1,
    cellRenderer: 'cellRendererNumber',
    cellRendererParams: {
      cutDecimalZeroes: true,
    },
    filter: 'numberColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.INVOICES.COVERED_DUE',
    field: 'coveredAmount',
    minWidth: 140,
    flex: 1,
    cellRenderer: 'cellRendererNumber',
    filter: 'numberColumnFilter',
    cellStyle: params => ({
      ...cellStyle(params),
      color: `var(--${params.value < 0 ? 'red60' : 'grey100'})`,
    }),
  },
  {
    headerName: 'INSTANCES.INVOICES.OUTSTANDING_DUE',
    field: 'outstandingAmount',
    minWidth: 170,
    flex: 1,
    cellRenderer: 'cellRendererNumber',
    filter: 'numberColumnFilter',
    cellStyle: params => ({
      ...cellStyle(params),
      color: `var(--${params.value < 0 ? 'red60' : 'grey100'})`,
    }),
  },
  {
    headerName: 'INSTANCES.CREATED',
    field: 'createdAt',
    flex: 1,
    minWidth: 150,
    filter: 'dateColumnFilter',
    cellStyle,
    valueFormatter: parseDate,
  },
  {
    headerName: 'INSTANCES.UPDATED',
    field: 'updatedAt',
    flex: 1,
    minWidth: 150,
    filter: 'dateColumnFilter',
    cellStyle,
    valueFormatter: parseDate,
  },
  {
    headerName: 'INSTANCES.INVOICES.CONFIRMED',
    field: 'confirmedAt',
    flex: 1,
    minWidth: 150,
    filter: 'dateColumnFilter',
    cellStyle,
    valueFormatter: parseDate,
  },
  {
    headerName: 'INSTANCES.INVOICES.CONFIRMED_BY',
    field: 'confirmedBy',
    flex: 1,
    minWidth: 145,
    filter: 'numberColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.INVOICES.SENT',
    field: 'sentAt',
    flex: 1,
    minWidth: 130,
    filter: 'dateColumnFilter',
    cellStyle,
    valueFormatter: parseDate,
  },
  {
    headerName: 'INSTANCES.INVOICES.SENT_BY',
    field: 'sentBy',
    flex: 1,
    minWidth: 130,
    filter: 'numberColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.ON_HOLD_NET',
    field: 'onHoldNetAmount',
    minWidth: 150,
    flex: 1,
    cellRenderer: 'cellRendererNumber',
    filter: 'numberColumnFilter',
    cellStyle: params => ({
      ...cellStyle(params),
      color: `var(--${params.value < 0 ? 'red60' : 'grey100'})`,
    }),
  },
  {
    headerName: 'INSTANCES.ON_HOLD_TAX',
    field: 'onHoldTaxAmount',
    minWidth: 150,
    flex: 1,
    cellRenderer: 'cellRendererNumber',
    filter: 'numberColumnFilter',
    cellStyle: params => ({
      ...cellStyle(params),
      color: `var(--${params.value < 0 ? 'red60' : 'grey100'})`,
    }),
  },
  {
    headerName: 'INSTANCES.ON_HOLD_TOTAL',
    field: 'onHoldTotalAmount',
    minWidth: 150,
    flex: 1,
    cellRenderer: 'cellRendererNumber',
    filter: 'numberColumnFilter',
    cellStyle: params => ({
      ...cellStyle(params),
      color: `var(--${params.value < 0 ? 'red60' : 'grey100'})`,
    }),
  },
  {
    headerName: 'INSTANCES.PERIOD_START_TZ',
    field: 'periodStartTz',
    flex: 1,
    headerComponentParams: {
      reservedList: 'timezones',
    },
    filter: 'listColumnFilter',
    minWidth: 205,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.PERIOD_START_ABSOLUTE',
    field: 'periodStartAbs',
    flex: 1,
    minWidth: 205,
    filter: 'dateColumnFilter',
    cellStyle,
    valueFormatter: parseDate,
  },
  {
    headerName: 'INSTANCES.PERIOD_END_TZ',
    field: 'periodEndTz',
    flex: 1,
    minWidth: 200,
    headerComponentParams: {
      reservedList: 'timezones',
    },
    filter: 'listColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.PERIOD_END_ABSOLUTE',
    field: 'periodEndAbs',
    flex: 1,
    minWidth: 200,
    filter: 'dateColumnFilter',
    cellStyle,
    valueFormatter: parseDate,
  },
]);
const CHARGES_DETAILS_OLD = [
  {
    headerName: 'INSTANCES.CHARGES.SOURCE',
    field: 'sources',
    flex: 1,
    cellStyle,
    cellRenderer: 'cellRendererTree',
    sortable: false,
  },
  {
    headerName: 'INSTANCES.COUNTRY',
    field: 'countryName',
    flex: 1,
    cellStyle,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.NETWORK',
    field: 'networkName',
    flex: 1,
    cellStyle,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.MCCMNC',
    field: 'mccMnc',
    flex: 1,
    cellStyle,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.CHARGES.COUNTRY_CODE',
    field: 'countryCode',
    flex: 1,
    cellStyle,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.TYPE',
    field: 'eventType',
    flex: 1,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.RATE',
    field: 'rate',
    flex: 1,
    cellStyle,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.CHARGES.BILLABLE_COUNT',
    field: 'billableCount',
    flex: 1,
    cellStyle,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.CHARGES.BILLABLE_VOL',
    field: 'billableVolume',
    flex: 1,
    cellStyle,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.CHARGES.NET_AMOUNT',
    field: 'netAmount',
    flex: 1,
    cellStyle,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.CHARGES.TAX_AMOUNT',
    field: 'taxAmount',
    flex: 1,
    cellStyle,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.CHARGES.TOTAL_AMOUNT',
    field: 'totalAmount',
    flex: 1,
    cellStyle,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.CHARGES.INFO',
    field: 'invoiceStatus', // ???
    flex: 1,
    cellStyle,
    sortable: false,
  },
];
const CLIENT_PROFILE_CHANNELS = [
  {
    headerName: 'INSTANCES.CLIENT_PROFILE.CHANNEL',
    field: 'channel',
    flex: 1,
    minWidth: 180,
    cellStyle,
    sortable: false,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    cellRenderer: 'cellRendererChannel',
  },
  {
    headerName: 'INSTANCES.CLIENT_PROFILE.CONNECTED',
    field: 'connected',
    flex: 1,
    cellStyle,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.CLIENT_PROFILE.DISABLED',
    field: 'disabled',
    flex: 1,
    cellStyle,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.CLIENT_PROFILE.OPT-OUT',
    field: 'optOut',
    flex: 1,
    cellStyle,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.CLIENT_PROFILE.API',
    field: 'api',
    flex: 1,
    cellStyle,
    sortable: false,
  },
];
const CLIENT_PROFILE_SENDERS = [
  {
    headerName: 'INSTANCES.CLIENT_PROFILE.CHANNEL',
    field: 'channel',
    flex: 1,
    cellStyle,
    sortable: false,
    minWidth: 180,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    cellRenderer: 'cellRendererChannel',
  },
  {
    headerName: 'INSTANCES.CLIENT_PROFILE.SENDER_ID',
    field: 'senderId',
    cellStyle,
    sortable: false,
    cellRenderer: 'cellRendererStyled',
    valueFormatter: ({ value }) => ({
      text: value,
      style: {
        fontWeight: 500,
      },
    }),
  },
  {
    headerName: 'INSTANCES.CLIENT_PROFILE.DIRECTION',
    field: 'direction',
    flex: 1,
    cellStyle,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.CLIENT_PROFILE.CONNECTED',
    field: 'connected',
    flex: 1,
    cellStyle,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.CLIENT_PROFILE.DISABLED',
    field: 'disabled',
    flex: 1,
    cellStyle,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.CLIENT_PROFILE.TEMPLATES',
    field: 'templates',
    flex: 1,
    cellStyle,
    sortable: false,
    valueFormatter: ({ value }) => ({
      tag: value && `${value} templates`,
      color: 'primary',
    }),
    cellRenderer: 'cellRendererChips',
  },
];
const CLIENT_PROFILE_INVOICES = [
  {
    headerName: 'INSTANCES.CLIENT_PROFILE.NUMBER_DOCUMENT',
    field: 'numberDocument',
    flex: 1,
    cellStyle,
    sortable: false,
    headerCheckboxSelection: true,
    checkboxSelection: true,
  },
  {
    headerName: 'INSTANCES.CLIENT_PROFILE.GET_DATE',
    field: 'getDate',
    flex: 1,
    cellStyle,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.CLIENT_PROFILE.PAY_DATE',
    field: 'payDate',
    flex: 1,
    cellStyle,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.CLIENT_PROFILE.PAY_SUM',
    field: 'paySum',
    flex: 1,
    cellStyle,
    sortable: false,
    valueFormatter: ({ value }) => `${value} ₽`,
  },
  {
    headerName: 'INSTANCES.STATUS',
    field: 'status',
    flex: 1,
    cellStyle,
    sortable: false,
    valueFormatter: ({ value }) => ({
      tag: value === 1 && 'paid'
        || value === 2 && 'not paid'
        || value === 3 && 'billed'
        || value === 4 && 'overdue',
      color: value === 1 && 'success'
        || value === 2 && 'danger'
        || value === 3 && 'primary'
        || value === 4 && 'default',
    }),
    cellRenderer: 'cellRendererChips',
  },
];
const CLIENT_PROFILE_TEMPLATES = [
  {
    headerName: 'INSTANCES.CLIENT_PROFILE.CHANNEL',
    field: 'channel',
    flex: 1,
    cellStyle,
    sortable: false,
    checkboxSelection: true,
    headerCheckboxSelection: true,
  },
  {
    headerName: 'INSTANCES.CLIENT_PROFILE.NAME',
    field: 'name',
    flex: 1,
    cellStyle,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.CLIENT_PROFILE.TEXT',
    field: 'text',
    flex: 1,
    cellStyle,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.CLIENT_PROFILE.DATE',
    field: 'date',
    flex: 1,
    cellStyle,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.STATUS',
    field: 'status',
    flex: 1,
    cellStyle,
    sortable: false,
  },
];
const CLIENT_PROFILE_COMMUNICATION_HISTORY = [
  {
    headerName: 'INSTANCES.CLIENT_PROFILE.CREATED',
    field: 'created',
    flex: 1,
    cellStyle,
    sortable: false,
    checkboxSelection: true,
    headerCheckboxSelection: true,
  },
  {
    headerName: 'INSTANCES.CLIENT_PROFILE.CHANNEL',
    field: 'channel',
    flex: 1,
    cellStyle,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.CLIENT_PROFILE.NAME',
    field: 'name',
    flex: 1,
    cellStyle,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.CLIENT_PROFILE.TEXT',
    field: 'text',
    flex: 1,
    cellStyle,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.CLIENT_PROFILE.SUBSCRIBERS',
    field: 'subscribers',
    flex: 1,
    cellStyle,
    sortable: false,
  },
];
const NOTIFICATIONS_SETTINGS = [
  {
    headerName: 'INSTANCES.NOTIFICATIONS_SETTINGS.CHANNEL',
    field: 'channelType',
    flex: 1,
    cellRenderer: 'cellRendererChannel',
    sortable: false,
    headerComponentParams: {
      withChips: false,
      byId: true,
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.NOTIFICATIONS_SETTINGS.EVENT',
    field: 'event',
    valueFormatter: ({ value }) => value,
    cellRenderer: 'cellRendererText',
    flex: 1,
    cellStyle,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.NOTIFICATIONS_SETTINGS.TEXT',
    field: 'template',
    cellRenderer: 'cellRendererNotificationText',
    flex: 1,
    cellStyle,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.NOTIFICATIONS_SETTINGS.VARIABLE',
    field: 'templateVariables',
    cellRenderer: 'cellRendererNotificationVariable',
    valueFormatter: ({ data }) => data?.template,
    flex: 1,
    cellStyle,
    sortable: false,
  },
  // {
  //   headerName: 'INSTANCES.NOTIFICATIONS_SETTINGS.SECTION',
  //   field: 'chapterUrl',
  //   cellRenderer: 'cellRendererSection',
  //   flex: 1,
  //   cellStyle,
  //   sortable: false,
  // },
  {
    headerName: 'INSTANCES.NOTIFICATIONS_SETTINGS.ADDITIONAL_EMAILS',
    field: 'receivers',
    cellRenderer: 'cellRendererNotificationReceivers',
    flex: 1,
    cellStyle,
    sortable: false,
  },
];
const NOTIFICATIONS_CHANNELS_SETTINGS = [
  {
    headerName: 'INSTANCES.NOTIFICATIONS_CHANNELS_SETTINGS.TYPE',
    field: 'channelType',
    cellStyle,
    cellRenderer: 'cellRendererChannel',
    sortable: false,
    headerComponentParams: {
      withChips: false,
      byId: true,
    },
    flex: 1,
  },
  {
    headerName: 'INSTANCES.NOTIFICATIONS_CHANNELS_SETTINGS.NAME',
    field: 'name',
    valueFormatter: ({ value }) => value,
    cellStyle,
    cellRenderer: 'cellRendererText',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'INSTANCES.NOTIFICATIONS_CHANNELS_SETTINGS.CONFIG',
    field: 'config',
    cellRenderer: 'cellRendererChannelConfig',
    valueFormatter: ({ data }) => {
      let resultValue = [];
      switch (data.channelType) {
        case 1:
          resultValue = [{ label: 'Uri', value: data.uri }];
          break;
        case 5:
          resultValue = [{ label: 'Token', value: data.token }];
          break;
        case 6:
          resultValue = [
            { label: 'Host', value: data.host },
            { label: 'Port', value: data.port },
            { label: 'TLS', value: String(data.useTls) },
            { label: 'Sender', value: data.emailSender },
            { label: 'Login', value: data.emailLogin },
            { label: 'Password', value: data.emailPassword },
            { label: 'Protocol', value: data.sslContextProtocol },
          ];
          break;
        default:
          break;
      }
      return resultValue;
    },
    flex: 1,
    wrapText: true,
    cellStyle,
    sortable: false,
  },
];
const PRINTABLE_FORMS = [
  {
    headerName: 'INSTANCES.ID',
    field: 'id',
    flex: 1,
    minWidth: 110,
    filter: 'numberColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.NAME',
    field: 'name',
    flex: 1,
    sortable: true,
    minWidth: 70,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.TYPE',
    field: 'type',
    flex: 1,
    minWidth: 110,
    filter: 'listColumnFilter',
    valueFormatter: ({ value }) => value?.name,
    headerComponentParams: {
      reservedList: 'printableFormTypes',
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.LEGAL_ENTITY',
    field: 'legalEntityId',
    flex: 1,
    minWidth: 110,
    sortable: true,
    filter: 'listColumnFilter',
    cellRenderer: 'cellRendererDictionaryMap',
    cellRendererParams: {
      dictionaryKey: 'ownLegalEntitiesDictionary',
    },
    headerComponentParams: {
      reservedList: 'ownLegalEntities',
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.RESOURCE',
    field: 'resourceId',
    flex: 1,
    minWidth: 110,
    sortable: true,
    filter: 'listColumnFilter',
    cellRenderer: 'cellRendererDictionaryMap',
    cellRendererParams: {
      dictionaryKey: 'resourcesDictionary',
    },
    headerComponentParams: {
      reservedList: 'resources',
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.PRINTABLE_FORMS.DATE_FROM',
    field: 'dateFrom',
    flex: 1,
    valueFormatter: parseDate,
    filter: 'dateColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.COMMENT',
    field: 'comment',
    flex: 1,
    minWidth: 110,
    filter: 'maskColumnFilter',
    cellStyle,
  },
];
const AUDIT_COLUMNS = [
  {
    field: 'time',
    headerName: 'INSTANCES.AUDIT.DATE_TIME',
    flex: 1,
    valueFormatter: parseDateWithTime,
    cellStyle,
    cellRenderer: 'cellRendererText',
    filter: 'dateColumnFilter',
    sortable: true,
  },
  {
    field: 'login',
    headerName: 'INSTANCES.AUDIT.USER',
    flex: 1,
    filter: 'maskColumnFilter',
    valueFormatter: ({ value }) => value,
    cellStyle,
    cellRenderer: 'cellRendererText',
    sortable: true,
  },
  {
    field: 'clientAddr',
    headerName: 'INSTANCES.AUDIT.IP',
    flex: 1,
    filter: 'maskColumnFilter',
    valueFormatter: ({ value }) => value,
    cellStyle,
    cellRenderer: 'cellRendererText',
    sortable: true,
  },
  {
    field: 'operation',
    headerName: 'INSTANCES.AUDIT.LOGGED_ACTION',
    flex: 1,
    filter: 'listColumnFilter',
    headerComponentParams: {
      reservedList: 'operations',
    },
    sortable: true,
    cellRenderer: 'cellRendererOperation',
  },
];
const SERVICES = [
  {
    headerName: 'INSTANCES.ID',
    field: 'id',
    flex: 1,
    sortable: true,
    filter: 'numberColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.NAME',
    field: 'name',
    flex: 1,
    sortable: false,
    minWidth: 70,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.TYPE',
    field: 'type',
    flex: 1,
    sortable: false,
    headerComponentParams: {
      items: SERVICE_TYPES_OPTIONS,
    },
    filter: 'listColumnFilter',
    valueFormatter: ({ value }) => SERVICE_TYPES_DICTIONARY[value],
    cellStyle,
  },
  {
    headerName: 'INSTANCES.SERVICES.SIZE',
    field: 'packSize',
    flex: 1,
    sortable: true,
    filter: 'numberColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CURRENCY',
    field: 'currency',
    flex: 1,
    sortable: true,
    headerComponentParams: {
      reservedList: 'currencies',
    },
    filter: 'listColumnFilter',
    cellStyle,
  },
];
const WABA_COLUMNS = [
  {
    headerName: 'INSTANCES.ID',
    field: 'id',
    flex: 1,
    sortable: true,
    filter: 'numberColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.COMPANY',
    field: 'companyId',
    flex: 1,
    sortable: true,
    filter: 'listColumnFilter',
    cellRenderer: 'cellRendererDictionaryMap',
    cellRendererParams: {
      dictionaryKey: 'companiesDictionary',
    },
    headerComponentParams: {
      reservedList: 'companies',
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.NAME',
    field: 'name',
    flex: 1,
    sortable: true,
    minWidth: 70,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.WABA_ID',
    field: 'wabaId',
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.TIMEZONE',
    field: 'tz',
    flex: 1,
    sortable: true,
    headerComponentParams: {
      reservedList: 'timezones',
    },
    filter: 'listColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.WABA.NAMESPACE',
    field: 'namespace',
    flex: 1,
    sortable: true,
    minWidth: 70,
    filter: 'maskColumnFilter',
    cellStyle,
  },
];
const PRE_MODERATION_MODERATION = [
  // {
  //   field: 'media',
  //   headerName: 'INSTANCES.PRE_MODERATION.MEDIA',
  //   flex: 1,
  //   cellStyle,
  //   checkboxSelection: true,
  //   headerCheckboxSelection: true,
  //   minWidth: 150,
  //   width: 70,
  //   sortable: false,
  //   cellRenderer: 'cellRendererFile',
  //   valueFormatter: ({ value }) => value?.reduce((acc, I) => {
  //     if (I.fileMessage) {
  //       acc.push(I.fileMessage);
  //     }
  //     if (I.thumbnailUrl) {
  //       acc.push(I);
  //     }
  //     return acc;
  //   }, []),
  // },
  {
    field: 'clientCompanyID',
    headerName: 'INSTANCES.PRE_MODERATION.CLIENT',
    flex: 1,
    valueFormatter: ({ value }) => value,
    cellStyle,
    sortable: true,
    cellRenderer: 'cellRendererDictionaryMap',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    cellRendererParams: {
      dictionaryKey: 'companiesDictionary',
    },
    headerComponentParams: {
      reservedList: 'companies',
    },
    filter: 'listColumnFilter',
  },
  {
    field: 'channel',
    headerName: 'INSTANCES.PRE_MODERATION.CHANNEL',
    flex: 1,
    cellStyle,
    cellRenderer: 'cellRendererChannels',
    sortable: false,
    minWidth: 130,
    headerComponentParams: {
      items: CHANNELS_STRING_VALUES,
    },
    filter: 'listColumnFilter',
  },
  {
    field: 'type',
    headerName: 'INSTANCES.PRE_MODERATION.TYPE',
    flex: 1,
    valueFormatter: ({ value }) => ([{
      id: value,
      icon: MODERATION_CONTENT_TYPES_DICTIONARY[value].icon,
      color: MODERATION_CONTENT_TYPES_DICTIONARY[value].color,
      title: MODERATION_CONTENT_TYPES_DICTIONARY[value].label,
      size: 16,
    }]),
    cellStyle,
    cellRenderer: 'cellRendererIcons',
    sortable: true,
    minWidth: 130,
    headerComponentParams: {
      items: MODERATION_CONTENT_TYPES_OPTIONS,
    },
    filter: 'listColumnFilter',
  },
  {
    field: 'externalName',
    headerName: 'INSTANCES.PRE_MODERATION.NAME',
    flex: 1,
    valueFormatter: ({ value }) => value,
    cellStyle,
    sortable: true,
    cellRenderer: 'cellRendererText',
    filter: 'maskColumnFilter',
  },
  {
    field: 'externalID',
    headerName: 'INSTANCES.PRE_MODERATION.ID',
    flex: 1,
    valueFormatter: ({ value }) => value,
    cellStyle,
    sortable: true,
    cellRenderer: 'cellRendererText',
    filter: 'numberColumnFilter',
  },
  {
    field: 'content',
    headerName: 'INSTANCES.PRE_MODERATION.TEXT_PREVIEW',
    flex: 1,
    valueFormatter: ({ value }) => value,
    cellStyle,
    sortable: true,
    cellRenderer: 'cellRendererText',
    filter: 'maskColumnFilter',
  },
  {
    field: 'createdAt',
    headerName: 'INSTANCES.PRE_MODERATION.CREATED',
    flex: 1,
    valueFormatter: parseDateWithTime,
    cellStyle,
    sortable: true,
    cellRenderer: 'cellRendererText',
    filter: 'dateColumnFilter',
  },
  // {
  //   field: 'recipientsCount',
  //   headerName: 'INSTANCES.PRE_MODERATION.RECEIVERS',
  //   flex: 1,
  //   valueFormatter: ({ value }) => value,
  //   cellStyle,
  //   sortable: false,
  //   cellRenderer: 'cellRendererText',
  // },
];
const PRE_MODERATION_HISTORY = [
  // {
  //   field: 'media',
  //   headerName: 'INSTANCES.PRE_MODERATION.MEDIA',
  //   flex: 1,
  //   cellStyle,
  //   minWidth: 150,
  //   width: 70,
  //   sortable: false,
  //   cellRenderer: 'cellRendererFile',
  //   valueFormatter: ({ value }) => value.reduce((acc, I) => {
  //     if (I.fileMessage) {
  //       acc.push(I.fileMessage);
  //     }
  //     if (I.thumbnailUrl) {
  //       acc.push(I);
  //     }
  //     return acc;
  //   }, []),
  // },
  {
    field: 'clientCompanyID',
    headerName: 'INSTANCES.PRE_MODERATION.CLIENT',
    flex: 1,
    valueFormatter: ({ value }) => value,
    cellStyle,
    sortable: true,
    cellRenderer: 'cellRendererDictionaryMap',
    cellRendererParams: {
      dictionaryKey: 'companiesDictionary',
    },
    headerComponentParams: {
      reservedList: 'companies',
    },
    filter: 'listColumnFilter',
  },
  {
    field: 'channel',
    headerName: 'INSTANCES.PRE_MODERATION.CHANNEL',
    flex: 1,
    cellStyle,
    cellRenderer: 'cellRendererChannels',
    sortable: false,
    minWidth: 130,
    headerComponentParams: {
      items: CHANNELS_STRING_VALUES,
    },
    filter: 'listColumnFilter',
  },
  {
    field: 'type',
    headerName: 'INSTANCES.PRE_MODERATION.TYPE',
    flex: 1,
    valueFormatter: ({ value }) => ([{
      id: value ? 'chat' : 'envelope',
      icon: value ? 'chat' : 'envelope',
      color: value ? 'var(--green70)' : 'var(--blue70)',
      title: value ? 'INSTANCES.PRE_MODERATION.SCENARIO' : 'INSTANCES.PRE_MODERATION.CAMPAIGN',
      size: 16,
    }]),
    cellStyle,
    cellRenderer: 'cellRendererIcons',
    sortable: true,
    minWidth: 130,
    headerComponentParams: {
      items: MODERATION_CONTENT_TYPES_OPTIONS,
    },
    filter: 'listColumnFilter',
  },
  {
    field: 'externalName',
    headerName: 'INSTANCES.PRE_MODERATION.NAME',
    flex: 1,
    valueFormatter: ({ value }) => value,
    cellStyle,
    sortable: true,
    cellRenderer: 'cellRendererText',
    filter: 'maskColumnFilter',
  },
  {
    field: 'externalID',
    headerName: 'INSTANCES.PRE_MODERATION.ID',
    flex: 1,
    valueFormatter: ({ value }) => value,
    cellStyle,
    sortable: false,
    cellRenderer: 'cellRendererText',
    filter: 'numberColumnFilter',
  },
  {
    field: 'content',
    headerName: 'INSTANCES.PRE_MODERATION.TEXT_PREVIEW',
    flex: 1,
    valueFormatter: ({ value }) => value,
    cellStyle,
    sortable: true,
    cellRenderer: 'cellRendererText',
    filter: 'maskColumnFilter',
  },
  {
    field: 'resolution',
    headerName: 'INSTANCES.PRE_MODERATION.STATUS',
    flex: 1,
    cellStyle,
    headerComponentParams: {
      items: MODERATION_STATUSES,
    },
    filter: 'listColumnFilter',
    valueFormatter: ({ value }) => {
      switch (value) {
        case 1: return ({
          tag: 'INSTANCES.PRE_MODERATION.APPROVED',
          color: 'success',
        });
        case 2: return ({
          tag: 'INSTANCES.PRE_MODERATION.REJECTED',
          color: 'danger',
        });
        case 3: return ({
          tag: 'INSTANCES.PRE_MODERATION.CANCELED',
          color: 'warning',
        });
        default: return {};
      }
    },
    cellRenderer: 'cellRendererChips',
    sortable: true,
  },
  {
    field: 'resolutionUserID',
    headerName: 'INSTANCES.PRE_MODERATION.MANAGER',
    flex: 1,
    valueFormatter: ({ value }) => value,
    cellStyle,
    sortable: true,
    filter: 'listColumnFilter',
    cellRenderer: 'cellRendererDictionaryMap',
    cellRendererParams: {
      fieldKeyToDisplay: 'username',
      dictionaryKey: 'boUsersDictionary',
    },
    headerComponentParams: {
      reservedList: 'boUsers',
    },
  },
  {
    field: 'createdAt',
    headerName: 'INSTANCES.PRE_MODERATION.CREATED',
    flex: 1,
    valueFormatter: parseDateWithTime,
    cellStyle,
    sortable: true,
    cellRenderer: 'cellRendererText',
    filter: 'dateColumnFilter',
  },
  // {
  //   field: 'recipientsCount',
  //   headerName: 'INSTANCES.PRE_MODERATION.RECEIVERS',
  //   flex: 1,
  //   valueFormatter: ({ value }) => value,
  //   cellStyle,
  //   sortable: false,
  //   cellRenderer: 'cellRendererText',
  // },
];
const PORTALS = [
  {
    headerName: '',
    field: 'brandSettings',
    cellRenderer: 'cellRendererPortalImage',
    cellStyle,
    width: 50,
    minWidth: 50,
    sortable: false,
  },
  {
    headerName: 'INSTANCES.ID',
    field: 'id',
    flex: 1,
    minWidth: 110,
    filter: 'numberColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.COMPANY',
    field: 'companyId',
    flex: 1,
    sortable: true,
    filter: 'listColumnFilter',
    cellRenderer: 'cellRendererDictionaryMap',
    cellRendererParams: {
      dictionaryKey: 'companiesDictionary',
    },
    headerComponentParams: {
      reservedList: 'companies',
    },
    cellStyle,
  },
  {
    headerName: 'INSTANCES.NAME',
    field: 'name',
    flex: 1,
    sortable: true,
    minWidth: 70,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'FQDN',
    field: 'fqdn',
    flex: 1,
    sortable: true,
    minWidth: 70,
    filter: 'maskColumnFilter',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.STATUS',
    field: 'status',
    minWidth: 130,
    flex: 1,
    filter: 'listColumnFilter',
    headerComponentParams: {
      items: PORTALS_STATUSES_OPTIONS,
    },
    valueFormatter: ({ value }) => ({
      tag: PORTALS_STATUSES_DICTIONARY[value]?.label,
      color: PORTALS_STATUSES_DICTIONARY[value]?.color,
    }),
    cellRenderer: 'cellRendererChips',
    cellStyle,
  },
  {
    headerName: 'INSTANCES.CREATED',
    field: 'createdAt',
    valueFormatter: parseDateWithTime,
    flex: 1,
    filter: 'dateColumnFilter',
    minWidth: 150,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.PORTALS.CREATED_BY',
    field: 'createdBy',
    valueFormatter: parseDateWithTime,
    flex: 1,
    minWidth: 150,
    cellStyle,
    filter: 'listColumnFilter',
    cellRenderer: 'cellRendererDictionaryMap',
    cellRendererParams: {
      fieldKeyToDisplay: 'username',
      dictionaryKey: 'boUsersDictionary',
    },
    headerComponentParams: {
      reservedList: 'boUsers',
    },
  },
  {
    headerName: 'INSTANCES.UPDATED',
    field: 'updatedAt',
    valueFormatter: parseDateWithTime,
    flex: 1,
    filter: 'dateColumnFilter',
    minWidth: 150,
    cellStyle,
  },
  {
    headerName: 'INSTANCES.PORTALS.UPDATED_BY',
    field: 'updatedBy',
    valueFormatter: parseDateWithTime,
    flex: 1,
    minWidth: 150,
    cellStyle,
    filter: 'listColumnFilter',
    cellRenderer: 'cellRendererDictionaryMap',
    cellRendererParams: {
      fieldKeyToDisplay: 'username',
      dictionaryKey: 'boUsersDictionary',
    },
    headerComponentParams: {
      reservedList: 'boUsers',
    },
  },
];
const SERVICES_ENABLED_MODAL = [
  {
    headerName: 'INSTANCES.ID',
    field: 'id',
  },
  {
    headerName: 'INSTANCES.SERVICE',
    field: 'serviceName',
  },
  {
    headerName: 'INSTANCES.SENDER_IDS.SERVICE_TYPE',
    field: 'serviceType',
  },
  {
    headerName: 'INSTANCES.CONNECTION_DATE',
    field: 'connectionDate',
  },
  {
    headerName: 'INSTANCES.EFFECTIVE_FROM',
    field: 'actualEffectiveFrom',
  },
  {
    headerName: 'INSTANCES.EFFECTIVE_TILL',
    field: 'actualEffectiveTill',
  },
];
const AGREEMENTS_MODAL = [
  {
    headerName: 'INSTANCES.STATUS',
    field: 'status',
  },
  {
    headerName: 'INSTANCES.AGREEMENT',
    field: 'agreement',
  },
  {
    headerName: 'INSTANCES.EFFECTIVE_FROM',
    field: 'actualEffectiveFrom',
  },
  {
    headerName: 'INSTANCES.EFFECTIVE_TILL',
    field: 'actualEffectiveTill',
  },
];

const WHATSAPP_TEMPLATES_COLUMNS = [
  {
    field: 'id',
    headerName: 'INSTANCES.ID',
    flex: 1,
    sortable: true,
    filter: 'numberColumnFilter',
    cellStyle,
  },
  {
    field: 'companyName',
    headerName: 'INSTANCES.COMPANY',
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
    cellStyle,
    minWidth: 118,
  },
  {
    field: 'name',
    headerName: 'INSTANCES.WHATS_APP_TEMPLATES.REGISTERED_NAME',
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
    cellStyle,
    minWidth: 170,
  },
  {
    field: 'category',
    headerName: 'INSTANCES.WHATS_APP_TEMPLATES.SERVICE_CATEGORY',
    flex: 1,
    sortable: true,
    filter: 'listColumnFilter',
    headerComponentParams: {
      reservedList: 'whatsAppCategories',
    },
    cellStyle,
    minWidth: 170,
  },
  {
    field: 'senderId',
    headerName: 'INSTANCES.SENDER_ID',
    flex: 1,
    valueFormatter: ({ data }) => data.senderDisplayName,
    sortable: true,
    filter: 'listColumnFilter',
    headerComponentParams: {
      reservedList: 'senders',
      filterKey: 'senderIdId',
    },
    cellStyle,
    minWidth: 118,
  },
  {
    field: 'status',
    headerName: 'INSTANCES.STATUS',
    flex: 1,
    sortable: true,
    valueFormatter: ({ value }) => {
      switch (value) {
        case 0: return ({
          tag: 'CONTROLS.WHATS_APP_TEMPLATES.DRAFT',
          color: 'warning',
          round: true,
        });
        case 1: return ({
          tag: 'CONTROLS.WHATS_APP_TEMPLATES.SUBMITTED',
          color: 'primary',
          round: true,
        });
        case 2: return ({
          tag: 'CONTROLS.WHATS_APP_TEMPLATES.APPROVED',
          color: 'success',
          round: true,
        });
        case -1: return ({
          tag: 'CONTROLS.WHATS_APP_TEMPLATES.REJECTED',
          color: 'danger',
          round: true,
        });
        case -2: return ({
          tag: 'CONTROLS.WHATS_APP_TEMPLATES.DELETED',
          color: 'danger',
          round: true,
        });
        case 3: return ({
          tag: 'CONTROLS.WHATS_APP_TEMPLATES.IN_APPEAL',
          color: 'warning',
          round: true,
        });
        case 4: return ({
          tag: 'CONTROLS.WHATS_APP_TEMPLATES.PENDING',
          color: 'warning',
          round: true,
        });
        case 5: return ({
          tag: 'CONTROLS.WHATS_APP_TEMPLATES.PENDING_DELETION',
          color: 'warning',
          round: true,
        });
        case 6: return ({
          tag: 'CONTROLS.WHATS_APP_TEMPLATES.DISABLED',
          color: 'danger',
          round: true,
        });
        case 7: return ({
          tag: 'CONTROLS.WHATS_APP_TEMPLATES.PAUSED',
          color: 'warning',
          round: true,
        });
        case 8: return ({
          tag: 'CONTROLS.WHATS_APP_TEMPLATES.LIMIT_EXCEEDED',
          color: 'danger',
          round: true,
        });
        default: return {};
      }
    },
    cellRenderer: 'cellRendererChips',
    filter: 'listColumnFilter',
    headerComponentParams: {
      items: WHATSAPP_TEMPLATES_STATUSES,
    },
    cellStyle,
    minWidth: 118,
  },
  {
    field: 'language',
    headerName: 'INSTANCES.WHATS_APP_TEMPLATES.LANGUAGE',
    flex: 1,
    filter: 'listColumnFilter',
    valueFormatter: ({ data }) => data.languageNameEn,
    sortable: true,
    headerComponentParams: {
      reservedList: 'whatsAppLanguages',
    },
    cellStyle,
    minWidth: 118,
  },
  {
    field: 'createdAt',
    headerName: 'INSTANCES.CREATED',
    valueFormatter: parseDate,
    flex: 1,
    sortable: true,
    filter: 'dateColumnFilter',
    headerComponentParams: {
      withoutEmpty: true,
    },
    cellStyle,
    minWidth: 118,
  },
];

export {
  MCC_MNC_COLUMNS,
  AUDIT_COLUMNS,
  DIAL_CODES,
  COMPANIES,
  USERS,
  MKIT_USERS,
  AGREEMENTS,
  ACCOUNTS,
  PAYMENTS,
  SENDER_IDS,
  APPLICATIONS,
  CHARGES,
  CHARGE_DETAILS,
  CHARGES_DETAILS_OLD,
  CLIENT_PROFILE_CHANNELS,
  CLIENT_PROFILE_SENDERS,
  CLIENT_PROFILE_INVOICES,
  CLIENT_PROFILE_TEMPLATES,
  CLIENT_PROFILE_COMMUNICATION_HISTORY,
  SMPP_CONNECTIONS,
  HTTP_CONNECTIONS,
  LEGAL_ENTITIES,
  SERVICES_ENABLED,
  INVOICES,
  PACKS_ENABLED,
  NOTIFICATIONS_SETTINGS,
  NOTIFICATIONS_CHANNELS_SETTINGS,
  PRINTABLE_FORMS,
  SERVICES,
  WABA_COLUMNS,
  PRE_MODERATION_HISTORY,
  PRE_MODERATION_MODERATION,
  PORTALS,
  SERVICES_ENABLED_MODAL,
  AGREEMENTS_MODAL,
  WHATSAPP_TEMPLATES_COLUMNS,
};
