import endpoints from 'consts/endpoints';

export default ({
  body,
  errorCallback,
  successCallback,
} = {}) => ({
  url: endpoints.getCreateReportUrl(),
  queryKey: endpoints.getCreateReportUrl(),
  meta: {
    errorCallback,
    successCallback,
    authToken: true,
  },
  body,
  options: {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
  },
  transform: response => ({ reports: response }),
  update: {
    reports: (oldValue = [], newValue) => {
      if (oldValue.find(O => O.name === newValue.name)) {
        const index = oldValue.indexOf(oldValue.find(O => O.name === newValue.name));

        return [
          ...oldValue.slice(0, index),
          newValue,
          ...oldValue.slice(index + 1),
        ];
      }

      return [...oldValue, newValue];
    },
  },
});
