import React from 'react';

import { useSmppConnectionsApi } from 'hooks/api';
import { useScreen } from 'hooks';
import Table from 'components/Table';
import ConfirmModal from 'components/modals/ConfirmModal';
import { CustomColumnsView } from 'components/blocks';
import { SMPP_CONNECTIONS } from 'consts/columns';
import Modal from 'components/Modal';

import SmppConnectionsForm from './SmppConnectionsForm';

import './style.scss';

const SmppConnectionsScreen = ({ screenRef }) => {
  const {
    getSmppConnections,
    postSmppConnection,
    putSmppConnection,
    deleteSmppConnection,
    smppConnections,
    smppConnectionsMeta,
    isPendingGetSmppConnections,
    isPendingPostSmppConnection,
    isPendingPutSmppConnection,
    isPendingDeleteSmppConnection,
    lastUpdatedGetSmppConnections,
  } = useSmppConnectionsApi();
  const {
    openModal,
    editableRecord,
    sendQuery,
    onCloseModal,
    limit,
    offset,
    setOffset,
    setLimit,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    onDeleteClick,
    isFormPristine,
    formValuesRef,
    isOpenConfirm,
    closeConfirm,
    onDeleteConfirm,
    onConfirmClose,
    isOpenModal,
    onRowClicked,
  } = useScreen({
    screenRef,
    getFunc: getSmppConnections,
    deleteFunc: deleteSmppConnection,
    onRowClickCallback: data => ({
      ...data,
      enabled: !data.enabled,
    }),
  });

  const onSubmit = (values) => {
    (editableRecord ? putSmppConnection : postSmppConnection)({
      body: {
        // TODO: убрать после того, как бэк поменяют или хер знает когда
        // companyId: '1',
        ...values,
      },
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
    });
  };

  return (
    <div className="smpp-connections-layout">
      <Table
        tableHeader="TABS.SMPP_CONNECTIONS"
        columnDefs={SMPP_CONNECTIONS}
        rowData={smppConnections}
        onRowClicked={onRowClicked}
        showFilters={false}
        openCustomColumns={openCustomViewDrawer}
        openForm={() => openModal('add')}
        buttonText="CONTROLS.SMPP.ADD"
        limit={limit}
        total={smppConnectionsMeta.size}
        page={offset}
        filteredColumns={filteredColumns}
        onChangePage={setOffset}
        onChangeLimit={setLimit}
        sendQuery={sendQuery}
        isPending={isPendingGetSmppConnections || !lastUpdatedGetSmppConnections}
        tableRef={tableRef}
      />
      {
        isOpenCustomViewDrawer && (
          <CustomColumnsView
            initialValues={filteredColumns}
            columnsNames={SMPP_CONNECTIONS}
            onSubmit={onSaveCustomView}
            onClose={closeCustomViewDrawer}
            isOpen={isOpenCustomViewDrawer}
          />
        )
      }
      {
        isOpenModal && (
          <Modal
            mode={isOpenModal}
            closeModal={onCloseModal}
            title="SCREENS.SMPP.SMPP_CONNECTION"
            editableRecord={editableRecord}
          >
            <SmppConnectionsForm
              onSubmit={onSubmit}
              onCancel={onCloseModal}
              initialValues={editableRecord}
              mode={isOpenModal}
              changeMode={openModal}
              onDelete={onDeleteClick}
              isFormPristine={isFormPristine}
              isPending={
                isPendingDeleteSmppConnection || isPendingPostSmppConnection || isPendingPutSmppConnection
              }
              formValuesRef={formValuesRef}
            />
          </Modal>
        )
      }
      {
        isOpenConfirm && (
          <ConfirmModal
            isDelete={isOpenConfirm === 'delete'}
            closeModal={closeConfirm}
            onConfirm={
              isOpenConfirm === 'delete' ? onDeleteConfirm : onConfirmClose
            }
          />
        )
      }
    </div>
  );
};

export default SmppConnectionsScreen;
