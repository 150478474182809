import React from 'react';

import Chips from '@setproduct-ui/core/Chips/Chips';
import { useUsersApi } from 'hooks/api';

const CompanyWithChipCellRenderer = ({
  value,
  valueFormatted,
}) => {
  const { currentUser } = useUsersApi();

  return (
    <>
      {valueFormatted}
      {value === currentUser?.companyId && (
        <Chips
          type="dense"
          view="smooth"
          round={false}
          color="primary"
          tag="Own"
          style={{ marginLeft: 5 }}
        />
      )}
    </>
  );
};

export default CompanyWithChipCellRenderer;
